const state = {
  configNavbarToggler: false,
};

const getters = {
  configNavbarToggler: (state) => state.configNavbarToggler,
};

const mutations = {
  setConfigNavbarToggler: (state, configNavbarToggler) => {
    state.configNavbarToggler = configNavbarToggler;
  },
};

const actions = {
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
