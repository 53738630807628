<template>
  <div
    class="col-md-8 col-sm-12 offset-lg-2 offset-sm-0 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-sample">
              <div class="section-heading">
                <h4 class="card-title">Agregar encuesta</h4>
              </div>
              <Form
                @submit="submit"
                :validation-schema="formValidation"
                v-slot="{ errors }"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="nombre">Nombre *</label>
                          <fieldset>
                            <Field
                              type="text"
                              name="nombre"
                              id="nombre"
                              class="form-control"
                              placeholder="Nombre"
                              v-model.trim="formData.nombre"
                            />
                            <MensajeValidacion :mensaje="errors.nombre" />
                          </fieldset>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="descripcion">Descripción *</label>
                          <fieldset>
                            <Field
                              type="text"
                              name="descripcion"
                              id="descripcion"
                              class="form-control"
                              placeholder="Descripción"
                              v-model.trim="formData.descripcion"
                            />
                            <MensajeValidacion :mensaje="errors.descripcion" />
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage :mensaje="mensaje" v-if="error" />
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <button
                      type="submit"
                      id="form-submit"
                      class="btn btn-primary mr-2"
                    >
                      Guardar
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="cerrar"
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <ModalMessages
          :mensaje="mensajeProceso"
          titulo="Agregar encuesta"
          :method="regresar"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../components/ErrorMessage.vue";
import ModalMessages from "../../../components/ModalMessages.vue";

export default {
  name: "EncuestaForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
    ModalMessages,
  },
  data() {
    const formValidation = {
      nombre: "required",
      descripcion: "required",
    };
    return {
      formData: {
        nombre: "",
        descripcion: "",
      },
      mensaje: "",
      formValidation,
      error: false,
      mensajeProceso: [],
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async submit() {
      this.mensajeProceso = [];
      this.error = false;
      this.loaderSpinner();
      const resp = await this.$store.dispatch("encuestas/crear", this.formData);
      this.loader.hide();
      if (!resp?.success) {
        this.error = true;
        this.mensajeProceso.push(resp?.errorMessage  + ", código " + resp?.status);
        document.getElementById("modalButton").click();
        return;
      }
      this.mensajeProceso.push(resp?.successMessage);
      document.getElementById("modalButton").click();
    },
    regresar() {
      if (!this.error) {
        this.$router.push({ name: "Encuestas" });
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
  },
};
</script>
