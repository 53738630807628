<template>
  <div v-for="(item, index)  in datos" :key="index">
    <div class="row">
      <div class="col-md-2 detail-title-type">
        <strong>{{ tipoCon(index) }}</strong>
      </div>
      <div class="col-md-2 detail-title-access">
        {{ item.accesoA }}
      </div>
      <div class="col-md-8 pl-0">
        <DetalleHora :datos="item.horas" :totalHoras="this.sumaHoras" />
      </div>
    </div>
  </div>
</template>
<script>
import DetalleHora from "@/views/reportes/components/DetalleHora.vue";

export default {
  components: { DetalleHora },
  props: {
    datos: {
      type: Object,
      required: true,
    },
    tipoConsulta: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sumaHoras: 0
    }
  },
  created() {
    this.datos.map((element) => {
      element.horas.map((item) => {
        this.sumaHoras += item.cantidad;
      });
    });
  },
  methods: {
    tipoCon(index) {
      if (index == 0) {
        return this.tipoConsulta;
      } else {
        return "";
      }
    }
  }
}
</script>
<style>
  .detail-title-type {
    border: 1px solid #ccc; 
    padding-top: 10px; 
    padding-left: 20px; 
    font-size: 10px;
  }
  .detail-title-access {
    border: 1px solid #ccc;
    padding-top: 10px; 
    font-size: 10px;
  }
</style>
