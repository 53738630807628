import axios from "axios";

const state = {
  exclusiones: null,
  exclusionSeleccionada: null,
  mensaje: "",
};

const getters = {
  exclusiones: (state) => state.exclusiones,
  exclusionSeleccionada: (state) => state.exclusionSeleccionada,
};

const mutations = {
  setExlusiones: (state, exclusiones) => {
    state.exclusiones = exclusiones;
  },
  setExclusionSeleccionada: (state, exclusionSeleccionada) => {
    state.exclusionSeleccionada = exclusionSeleccionada;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
  inicializar: (state) => {
    state.exclusiones = null;
    state.exclusionSeleccionada = null;
    state.mensaje = ";";
  },
};

const actions = {
  async consultar({ commit }, data) {
    try {
      let res = await axios.get(
        `/v1/exclusiones/${data.pilar}/pilar/${data.estado}/estado`
      );
      commit("setExlusiones", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async crear({ commit }, data) {
    try {
      const headers = { "Content-Type": "multipart/form-data" };
      await axios.post(`/v1/exclusiones`, data, { headers });
      commit("setMensaje", "Se creó exitosamente la exclusión");
      return ["OK", "Se creó exitosamente la exclusión"];
    } catch (error) {
      return [
        "ERROR",
        error?.response?.data?.errors?.[0] || "No se pudo crear la exclusión",
      ];
    }
  },
  async eliminar({ dispatch }, data) {
    try {
      await axios.delete(`/v1/exclusiones/${data.id}`);
      dispatch("consultar");
    } catch (error) {
      return error;
    }
  },
  async consultarExclusionesByUsuario({ commit }, data) {
    try {
      let res = await axios.get(`/v1/exclusiones/${data.estado}/estado`);
      commit("setExlusiones", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async aprobar({ commit }, data) {
    try {
      await axios.get(`/v1/exclusiones/${data?.idExclusion}/aprobar`);
      commit("setMensaje", "Se aprobó exitosamente la exclusión");
    } catch (error) {
      return error;
    }
  },
  async rechazar({ commit }, data) {
    try {
      await axios.post(
        `/v1/exclusiones/${data?.idExclusion}/rechazar`,
        data?.motivo
      );
      commit("setMensaje", "Se rechazó exitosamente la exclusión");
    } catch (error) {
      return error;
    }
  },
  async aprobarTodos({ commit }, data) {
    try {
      await axios.post(`/v1/exclusiones/aprobar`, data);
      commit("setMensaje", "Se aprobó exitosamente las exclusiones");
    } catch (error) {
      return error;
    }
  },
  async rechazarTodos({ commit }, data) {
    try {
      await axios.post(`/v1/exclusiones/rechazar`, data);
      commit("setMensaje", "Se rechazó exitosamente las exclusiones");
    } catch (error) {
      return error;
    }
  },
  async actualizarEstado({ commit }, data) {
    try {
      if(data?.motivoRechazo){
        await axios.put(`/v1/exclusiones/${data?.idExclusion}/cambiar-estado?estadoExlusion=${data?.estadoExlusion}&motivoRechazo=${data?.motivoRechazo}`);
      }else{
        await axios.put(`/v1/exclusiones/${data?.idExclusion}/cambiar-estado?estadoExlusion=${data?.estadoExlusion}`);
      }

      commit("setMensaje", "Se modificó el estado exitosamente");
    } catch (error) {
      return error;
    }
  },
  async consultarXFechas({ commit }, data) {
    try {
      let res = await axios.get(
        `/v1/exclusiones/reporte?fechaInicial=${data.fechaInicio}&fechaFinal=${data.fechaFin}`
      );
      commit("setExlusiones", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarReporteAcumuladoUsuarioXFechas({ commit }, data) {
    try {
      let res = await axios.get(
        `/v1/exclusiones/usuario/reporte?fechaInicial=${data.fechaInicio}&fechaFinal=${data.fechaFin}`
      );
      commit("setExlusiones", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarReporteAcumuladoAuditorXFechas({ commit }, data) {
    try {
      let res = await axios.get(
        `/v1/exclusiones/auditor/reporte?fechaInicial=${data.fechaInicio}&fechaFinal=${data.fechaFin}`
      );
      commit("setExlusiones", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async procesarExcel({ commit, dispatch }, data) {
    try {
      const headers = { "Content-Type": "multipart/form-data" };
      await axios.post(`/v1/exclusiones/${data?.idExclusion}/aprobar`, data?.formData, { headers });
      commit("setMensaje", "Se procesó el archivo con éxito");

      dispatch("consultar", {
        pilar: data?.pilar,
        estado: data?.estado
      });
    } catch (error) {
      return error;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
