<template>
  <div
    class="modal fade show"
    id="modalAsignacion"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalAsignacionLabel"
    aria-modal="true"
    style="display: block"
    v-if="componentHide"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalAsignacionLabel">
            Asignar encuesta
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="form-group col-md-12">
              <label>Perfil</label>
              <select class="form-control" v-model="perfil">
                <option v-for="item in perfiles" :key="item" :value="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="close"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="method(perfil)"
            data-dismiss="modal"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    method: { type: Function },
    close: { type: Function },
    componentHide: Boolean,
  },
  data(){
    return{
      perfil: "",
      perfiles: [
        "ROLE_LOGISTICA"
      ]
    }
  },
};
</script>
