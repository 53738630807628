<template>
  <div
    class="col-md-8 col-sm-12 offset-lg-2 offset-sm-0 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-sample">
              <div class="section-heading">
                <h4 class="card-title">Agregar un corte</h4>
              </div>
              <Form
                @submit="submit"
                :validation-schema="formValidation"
                v-slot="{ errors }"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Año *</label>
                          <fieldset>
                            <Field
                              as="select"
                              name="anio"
                              class="form-control"
                              placeholder="AÑO"
                              v-model="anio"
                            >
                              <option value="" disabled>
                                Seleccione un año
                              </option>
                              <option
                                v-for="item in anios"
                                :key="item"
                                :value="item"
                              >
                                {{ item }}
                              </option>
                            </Field>
                            <MensajeValidacion :mensaje="errors.anio" />
                          </fieldset>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Mes *</label>
                          <fieldset>
                            <Field
                              as="select"
                              name="mes"
                              class="form-control"
                              placeholder="MES"
                              v-model="mes"
                            >
                              <option value="" disabled>
                                Seleccione un mes
                              </option>
                              <option
                                v-for="item in meses"
                                :key="item?.id"
                                :value="item?.id"
                              >
                                {{ item?.descripcion }}
                              </option>
                            </Field>
                            <MensajeValidacion :mensaje="errors.mes" />
                          </fieldset>
                        </div>
                      </div>
                    </div>
                    <h5>Cortes</h5>
                    <hr />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Día máximo corte 1 *</label>
                          <fieldset>
                            <Field
                              as="select"
                              name="diaCorte1"
                              class="form-control"
                              v-model="diaCorte1"
                            >
                              <option value="" disabled>
                                Seleccione un día
                              </option>
                              <option
                                v-for="item in dias"
                                :key="item"
                                :value="item"
                              >
                                {{ item }}
                              </option>
                            </Field>
                            <MensajeValidacion :mensaje="errors.diaCorte1" />
                          </fieldset>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Día máximo corte 2 *</label>
                          <fieldset>
                            <Field
                              as="select"
                              name="diaCorte2"
                              class="form-control"
                              v-model="diaCorte2"
                            >
                              <option value="" disabled>
                                Seleccione un día
                              </option>
                              <option
                                v-for="item in dias"
                                :key="item"
                                :value="item"
                              >
                                {{ item }}
                              </option>
                            </Field>
                            <MensajeValidacion :mensaje="errors.diaCorte2" />
                          </fieldset>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="observacion">Observación</label>
                      <textarea
                        class="form-control"
                        id="observacion"
                        rows="3"
                        v-model="observacion"
                      ></textarea>
                    </div>
                  </div>
                  <ErrorMessage :mensaje="mensaje" v-if="error" />
                </div>

                <div class="row mt-3">
                  <div class="col-lg-12">
                    <button
                      type="submit"
                      id="form-submit"
                      class="btn btn-primary mr-2"
                    >
                      Guardar
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="cerrar"
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../components/ErrorMessage";

export default {
  name: "CorteForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
  },
  data() {
    const formValidation = {
      mes: "required",
      anio: "required",
      diaCorte1: "required",
      diaCorte2: "required",
    };
    return {
      mes: "",
      anio: "",
      diaCorte1: "",
      diaCorte2: "",
      observacion: "",
      error: false,
      mensaje: "",
      formValidation,
      meses: [
        { id: 1, descripcion: "ENERO" },
        { id: 2, descripcion: "FEBRERO" },
        { id: 3, descripcion: "MARZO" },
        { id: 4, descripcion: "ABRIL" },
        { id: 5, descripcion: "MAYO" },
        { id: 6, descripcion: "JUNIO" },
        { id: 7, descripcion: "JULIO" },
        { id: 8, descripcion: "AGOSTO" },
        { id: 9, descripcion: "SEPTIEMBRE" },
        { id: 10, descripcion: "OCTUBRE" },
        { id: 11, descripcion: "NOVIEMBRE" },
        { id: 12, descripcion: "DICIEMBRE" },
      ],
      anios: [2022, 2023, 2024, 2025, 2026],
      dias: [],
    };
  },
  mounted() {
    this.cargarDias();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async cargarDias() {
      if (this.corteSeleccionado) {
        const { anio, diaCorte1, diaCorte2, mes, observacion } =
          this.corteSeleccionado;
        this.anio = anio;
        this.mes = mes;
        this.observacion = observacion;
        await this.calcularDiasMes();
        this.diaCorte1 = diaCorte1;
        this.diaCorte2 = diaCorte2;
      }
    },
    mostrarClientes() {
      if (this.formData.opcion === "Seleccionar Clientes") {
        this.habilitarClientes = true;
      } else {
        this.habilitarClientes = false;
      }
    },
    calcularDiasMes() {
      const anio = this.anio;
      const mes = this.mes;

      if (!(this.anio && this.mes)) {
        return;
      }

      this.dias = [];
      this.diaCorte1 = "";
      this.diaCorte2 = "";

      let diasMes = new Date(anio, mes, 0).getDate();
      for (let dia = 1; dia <= diasMes; dia++) {
        this.dias.push(dia);
      }
    },
    async submit() {
      const data = {
        anio: this.anio,
        diaCorte1: this.diaCorte1,
        diaCorte2: this.diaCorte2,
        mes: this.mes,
        observacion: this.observacion,
      };
      try {
        if (this.corteSeleccionado) {
          await this.$store.dispatch("cortes/editar", {
            idConfigCorte: this.corteSeleccionado.id,
            data,
          });
          await this.$store.commit("cortes/setCorteioSeleccionado", null);
        } else {
          await this.$store.dispatch("cortes/crear", data);
        }
        this.$router.go(-1);
      } catch (error) {
        console.log(error);
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    corteSeleccionado() {
      return this.$store.getters["cortes/corteSeleccionado"]
        ? this.$store.getters["cortes/corteSeleccionado"]
        : null;
    },
  },
  watch: {
    mes(mesAnterior, nuevoMes) {
      if (mesAnterior !== nuevoMes) {
        this.calcularDiasMes();
      }
    },
    anio(anioAnterior, nuevoAnio) {
      if (anioAnterior !== nuevoAnio) {
        this.calcularDiasMes();
      }
    },
  },
};
</script>
