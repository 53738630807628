<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-3">
            <h3 class="font-weight-bold">Gestión de Ruta Logistica</h3>
          </div>
          <div class="col-md-9">
            <button @click="agregar" class="btn btn-primary float-right">
              <span class="icon-plus"></span> Configurar Ruta
            </button>
          </div>
        </div>
        <br/>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th></th>
              <th>Fecha</th>
              <th>Usuario Carga</th>
              <th>Cliente</th>
              <th>Usuario Asignado</th>
              <th>Motivo</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in registros" :key="item.id">
              <td width="100">
                <a href="javascript:void(0)" @click="eliminar(item.id)"
                ><i class="ti-trash"></i></a
                >
              </td>
              <td>
                {{item.fecha}}
              </td>
              <td>
                {{item.usuarioCarga}}
              </td>
              <td>
                {{item.clienteSap}}
              </td>
              <td>
                {{item.usuario}}
              </td>
              <td>
                {{item.motivo}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>
<script>


export default {
  name: "GestionRutaLogistica",
  data() {
    return {

    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("logistica/consultar");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },

    async agregar() {
      this.$router.push({name: "RutaLogisticaForm"});
    },
    async eliminar() {
    },
  },
  computed: {

    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },

    registros() {
      return this.$store.getters["logistica/registros"]
          ? this.$store.getters["logistica/registros"]
          : false;
    }

  },
  components: { },
};
</script>
