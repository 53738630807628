<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Pilar Usuario</h3>
          </div>
          <div class="col-md-6">
            <button @click="agregar" class="btn btn-primary float-right">
              <span class="icon-plus"></span> Agregar
            </button>
          </div>
        </div>
        <br />
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Usuario</th>
                <th>Pilar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in pilares" :key="item.id">
                <td>
                  <a href="javascript:void(0)" @click="editar(item)"
                    ><i class="ti-pencil"></i></a
                  >
                </td>
                <td>{{ item?.usuario }}</td>
                <td>{{ item?.pilar }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GestionPilares",
  data() {
    return {};
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("pilares/consultarAll");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      await this.$store.commit("pilares/setPilarSeleccionado", null);
      this.$router.push({ name: "PilarForm" });
    },
    async editar(pilar) {
      await this.$store.commit("pilares/setPilarSeleccionado", pilar);
      this.$router.push({ name: "PilarForm" });
    },
    async eliminar(id) {
        console.log(id)

    },
  },
  computed: {
    pilares() {
      return this.$store.getters["pilares/pilares"]
        ? this.$store.getters["pilares/pilares"]
        : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
  },
};
</script>
