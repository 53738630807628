import axios from "axios";
import router from "../../router/index";

const state = {
  token: null,
  rol: null,
  user: null
};

const getters = {
  isLogged: (state) => state.token !== null,
  getPerfil: (state) => state.rol,
};

const mutations = {
  setLoginInfo: (state, data) => {
    state.token = data.token;
    state.rol = data.rol;
  },
  clearAuthData: (state) => {
    state.token = null;
    state.rol = null;
  },
};

const actions = {
  async login({ commit }, data) {
    try {
      const resp = await axios.post("/v1/auth/login", {
        usuario: data.usuario,
        clave: data.clave,
      });
      window.sessionStorage.setItem("_token_exclusiones", resp.data.token);
      commit("setLoginInfo", resp.data);
      return resp;
    } catch (error) {
      window.sessionStorage.removeItem("_token_exclusiones");
      commit("clearAuthData");
      return {
          error: true
      };
    }
  },
  logout: ({ commit }) => {
    window.sessionStorage.removeItem("_token_exclusiones");
    commit("clearAuthData");
    router.push({ name: "Login" });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
