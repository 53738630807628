import axios from "axios";

const state = {
  usuarios: null,
  usuarioSeleccionado: null,
  mensaje: "",
  listaUsuarios: []
};

const getters = {
  usuarios: (state) => state.usuarios,
  usuarioSeleccionado: (state) => state.usuarioSeleccionado,
  listaUsuarios: (state) => state.listaUsuarios,
};

const mutations = {
  setUsuarios: (state, usuarios) => {
    state.usuarios = usuarios;
  },
  setUsuarioSeleccionado: (state, usuarioSeleccionado) => {
    state.usuarioSeleccionado = usuarioSeleccionado;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
  setListaUsuarios: (state, listaUsuarios) => {
    state.listaUsuarios = listaUsuarios;
  },
  inicializar: (state) => {
    state.usuarios = null;
    state.usuarioSeleccionado = null;
    state.mensaje = "";
  },
};

const actions = {
  async consultarAll({ commit }) {
    try {
      let res = await axios.get(`/v1/usuarios`);
      commit("setUsuarios", res?.data);
    } catch (error) {
      return error;
    }
  },
  async crear({ commit }, data) {
    try {
      await axios.post(`/v1/usuarios/asignacion`, data);
      commit("setMensaje", "Se creó exitosamente el usuario");
    } catch (error) {
      return error;
    }
  },
  async editar({ commit }, data) {
    try {
      await axios.put(`/v1/usuarios/${data.idGerenciaUsuario}`, data?.data);
      commit("setMensaje", "Se actualizó exitosamente el usuario");
    } catch (error) {
      return error;
    }
  },
  async consultarUsuarios({ commit }, data) {
    try {
      const resp = await axios.post(`/v1/usuarios/busqueda`, data);
      commit("setListaUsuarios", resp?.data?.result);
    } catch (error) {
      return error;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
