<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-3">
            <h3 class="font-weight-bold">Gestión de Exclusiones</h3>
          </div>
          <div class="col-md-9">
            <button @click="agregar" class="btn btn-primary float-right">
              <span class="icon-plus"></span> Agregar
            </button>
          </div>
        </div>
        <br />
        <div class="table-responsive" @change="consultar">
          <table class="table table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Cliente</th>
                <th>Fecha exclusión</th>
                <th>ECB</th>
                <th>Nombre ECB</th>
                <th>Nombre Gerente General</th>
                <th>Nombre Gerente Ventas</th>
                <th>Tipo</th>
                <th>Motivo</th>
                <th>Pilar</th>
                <th>Estado</th>

              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in exclusiones"
                :key="item.id"
              >
                <td>
                  <a
                    href="javascript:void(0)"
                    v-if="item?.clientes?.length"
                    @click="seleccionarClientes(item?.clientes ?? [])"
                    data-toggle="modal"
                    data-target="#modalClientes"
                    ><i class="ti-user"></i></a
                  >&nbsp;
                  <a
                    href="javascript:void(0)"
                    @click="consultarAdjuntos(item?.id)"
                    ><i class="ti-cloud-down"></i
                  ></a>
                </td>
                <td>{{ item?.cliente }}</td>
                <td>{{ item?.fechaExclusion }}</td>
                <td>{{ item?.ecb }}</td>
                <td>{{ item?.nombreFfvv }}</td>
                <td>{{ item?.gerenteGeneral }}</td>
                <td>{{ item?.gerenteVentas }}</td>
                <td>{{ item?.tipo }}</td>
                <td>{{ item?.motivo }}</td>
                <td>{{ item?.pilar }}</td>
                <td>
                  <div
                    class="badge"
                    :class="
                      item?.estadoExclusion === 'APROBADO'
                        ? 'badge-success'
                        : item?.estadoExclusion === 'PENDIENTE'
                        ? 'badge-secondary'
                        : 'badge-danger'
                    "
                  >
                    {{ item.estadoExclusion }}
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
        <Clientes :listaClientes="clientes" titulo="Clientes" />
        <Adjuntos titulo="Lista de archivos" :listaAdjuntos="adjuntos" />
        <button
          id="adjuntosId"
          type="button"
          data-toggle="modal"
          data-target="#modalAdjuntos"
          v-show="false"
        ></button>
      </div>
    </div>
  </div>
</template>
<script>
import Clientes from "../../../components/Clientes.vue";
import Adjuntos from "../../../components/Adjuntos.vue";
export default {
  name: "GestionExclusiones",
  data() {
    return {
      clientes: [],
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch(
          "exclusiones/consultarExclusionesByUsuario",
          { estado: "PENDIENTE" }
        );
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    seleccionarClientes(clientes) {
      this.clientes = clientes;
    },
    async agregar() {
      await this.$store.commit("exclusiones/setExclusionSeleccionada", null);
      this.$router.push({ name: "ExclusionForm" });
    },
    async eliminar(id) {
      console.log(id);
      /* this.$swal({
              text: "¿Está seguro de eliminar la información?",
              //icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Si",
              cancelButtonText: "No",
            }).then(async (result) => {
              if (result.isConfirmed) {
                this.loaderSpinner();
                try {
                  await this.$store.dispatch("usuarios/eliminar", { id: id });
                  this.$swal({
                    text: "Registro eliminado con éxito.",
                    icon: "success",
                  });
                } catch (error) {
                  this.$swal("", error.message, "error");
                } finally {
                  this.loader.hide();
                }
              }
            }); */
    },
    async consultarAdjuntos(exclusion) {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("adjuntos/consultarAdjuntos", {
          idExclusion: exclusion,
        });
        document.getElementById("adjuntosId").click();
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
  },
  computed: {
    exclusiones() {
      return this.$store.getters["exclusiones/exclusiones"]
        ? this.$store.getters["exclusiones/exclusiones"]
        : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    adjuntos() {
      return this.$store.getters["adjuntos/adjuntos"]
        ? this.$store.getters["adjuntos/adjuntos"]
        : [];
    },
  },
  components: { Clientes, Adjuntos },
};
</script>
