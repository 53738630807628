<template>
  <ConfigurarCortes />
</template>
<script>
import ConfigurarCortes from "./components/ConfigurarCortes";
export default {
  components: { ConfigurarCortes },
  created(){
    if (!this.$store.getters["auth/isLogged"]) {
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
  }
};
</script>