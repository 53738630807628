<template>
  <div
      class="col-md-8 col-sm-12 offset-lg-2 offset-sm-0 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-sample">
              <div class="section-heading">
                <h4 class="card-title">Configurar Ruta Logistica</h4>
              </div>
              <Form
                  @submit="submit"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="custom-control custom-switch">
                          <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customSwitch1"
                              @change="activaSobreescribir"
                              :checked="sobreecribir"
                          />
                          <label class="custom-control-label" for="customSwitch1"
                          >Sobreescribir</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6 mt-3">
                        <label for="formFile" class="form-label"
                        >Cargar archivo ruta</label
                        >
                        <input
                            class="form-control"
                            type="file"
                            id="file"
                            ref="file"
                            @change="uploadFile"
                        />
                      </div>
                    </div>
                  </div>
                  <ErrorMessage :mensaje="mensaje" v-if="error"/>
                </div>

                <div class="row mt-3">
                  <div class="col-lg-12">
                    <button
                        type="submit"
                        id="form-submit"
                        class="btn btn-primary mr-2"
                        :disabled="!file"
                    >
                      Guardar
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        @click="cerrar"
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <ModalMessages
            :mensaje="mensajeProceso"
            titulo="Carga de ruta logistica"
            :method="regresar"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {Form} from "vee-validate";
import ErrorMessage from "../../../components/ErrorMessage";
import ModalMessages from "../../../components/ModalMessages.vue";

export default {
  name: "RutaLogisticaForm",
  components: {
    ErrorMessage,
    Form,
    ModalMessages,
  },
  data() {
    const formValidation = {
      cliente: "required",
      usuario: "required",
      file: "required"
    };
    return {
      formData: {
        cliente: "",
        usuario: ""
      },
      error: false,
      mensaje: "",
      formValidation,
      file: null,
      mensajeProceso: [],
      sobreecribir: false
    };
  },
  mounted() {

  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },


    uploadFile() {
      this.file = this?.$refs?.file?.files[0];
    },

    async submit() {
      this.loaderSpinner();
      let formData = new FormData();
      formData.append('file', this.file);
      const formCompuesto = {
        form : formData,
        sobreecribir : this.sobreecribir
      }
      const resp = await this.$store.dispatch("logistica/cargar", formCompuesto);
      this.mensajeProceso=[]
      if (resp.success) {
        this.error = false;
        this.mensajeProceso.push('Correctos: ' + resp.data.correctos);
        this.mensajeProceso.push('Fallidos: ' + resp.data.fallidos);
        document.getElementById("modalButton").click();
      } else {
        this.error = true
        this.mensajeProceso = [
          resp.error.errors[0]
        ];
        document.getElementById("modalButton").click();
      }
      this.loader.hide();
    },
    regresar() {
      if (!this.error) {
        this.$router.go(-1);
      }
    },
    cerrar() {
      this.$router.go(-1);
    },

    activaSobreescribir() {
      this.sobreecribir = !this.sobreecribir
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
  },
};
</script>
<style>

</style>
