<template>
  <div
    class="col-md-8 col-sm-12 offset-lg-2 offset-sm-0 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-sample">
              <div class="section-heading">
                <h4 class="card-title">
                  {{
                    usuarioSeleccionado ? "Editar usuario" : "Agregar usuario"
                  }}
                </h4>
              </div>
              <Form
                @submit="submit"
                :validation-schema="formValidation"
                v-slot="{ errors }"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="usuario">Usuario *</label>
                          <fieldset>
                            <Field
                              type="text"
                              name="usuario"
                              id="usuario"
                              class="form-control"
                              placeholder="Usuario"
                              v-model.trim="formData.usuario"
                              :disabled="usuarioSeleccionado?.usuario"
                              @keyup="consultarUsuario"
                            />
                            <MensajeValidacion :mensaje="errors.usuario" />
                          </fieldset>
                          <ListaUsuarios
                            :lista="listaUsuarios"
                            :seleccionar="seleccionarUsuario"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Gerencia General *</label>
                          <fieldset>
                            <Field
                              as="select"
                              name="gerenciaGeneral"
                              class="form-control"
                              placeholder="Gerencia General"
                              v-model="formData.gerenciaGeneral"
                              @change="consultarGerenciasVentas"
                            >
                              <option value="" disabled>
                                Seleccione una gerencia
                              </option>
                              <option
                                v-for="item in gerenciasGenerales"
                                :key="item.descripcion"
                                :value="item.descripcion"
                              >
                                {{ item.descripcion }}
                              </option>
                            </Field>
                            <MensajeValidacion
                              :mensaje="errors.gerenciaGeneral"
                            />
                          </fieldset>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Gerencia Ventas *</label>
                          <fieldset>
                            <Field
                              as="select"
                              name="gerenciaVentas"
                              class="form-control"
                              placeholder="Gerencia Ventas"
                              v-model="formData.gerenciaVentas"
                            >
                              <option value="" disabled>
                                Seleccione una gerencia
                              </option>
                              <option
                                v-for="item in gerenciasVentas"
                                :key="item.descripcion"
                                :value="item.descripcion"
                              >
                                {{ item.descripcion }}
                              </option>
                            </Field>
                            <MensajeValidacion
                              :mensaje="errors.gerenciaVentas"
                            />
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage :mensaje="mensaje" v-if="error" />
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <button
                      type="submit"
                      id="form-submit"
                      class="btn btn-primary mr-2"
                    >
                      Guardar
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="cerrar"
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";
import ListaUsuarios from "../../../../components/ListaUsuarios.vue";

export default {
  name: "UsuarioForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
    ListaUsuarios,
  },
  data() {
    const formValidation = {
      usuario: "required",
      gerenciaGeneral: "required",
      gerenciaVentas: "required",
    };
    return {
      formData: {
        usuario: "",
        gerenciaGeneral: "",
        gerenciaVentas: "",
      },
      mensaje: "",
      formValidation,
      error: false,
    };
  },
  mounted() {
    this.consultarGerenciasGenerales();
    if (this.usuarioSeleccionado) {
      this.formData = { ...this.usuarioSeleccionado };
      this.consultarGerenciasVentas();
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarUsuario(e) {
      const filtro = e.target.value;
      if (filtro?.length < 1) {
        this.$store.commit("usuarios/setListaUsuarios", []);
        return;
      }
      try {
        await this.$store.dispatch("usuarios/consultarUsuarios", {
          usuario: filtro,
        });
      } catch (error) {
        console.log(error);
      }
    },
    seleccionarUsuario(usuario) {
      this.formData.usuario = usuario;
      this.$store.commit("usuarios/setListaUsuarios", []);
    },
    async consultarGerenciasGenerales() {
      try {
        await this.$store.dispatch("catalogos/consultarGerenciasGenerales");
      } catch (error) {
        console.log(error);
      }
    },
    async consultarGerenciasVentas() {
      try {
        await this.$store.dispatch("catalogos/consultarGerenciasVentas", {
          gerenciaGeneral: this.formData.gerenciaGeneral.toString(),
        });
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      this.error = false;
      this.loaderSpinner();
      try {
        if (this.usuarioSeleccionado) {
          await this.$store.dispatch("usuarios/editar", {
            idGerenciaUsuario: this.usuarioSeleccionado.id,
            data: this.formData,
          });
          await this.$store.commit("usuarios/setUsuarioSeleccionado", null);
        } else {
          await this.$store.dispatch("usuarios/crear", this.formData);
        }
        this.$router.push({ name: "Usuarios" });
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {
    usuarioSeleccionado() {
      return this.$store.getters["usuarios/usuarioSeleccionado"]
        ? this.$store.getters["usuarios/usuarioSeleccionado"]
        : null;
    },
    gerenciasGenerales() {
      return this.$store.getters["catalogos/gerenciasGenerales"]
        ? this.$store.getters["catalogos/gerenciasGenerales"]
        : [];
    },
    gerenciasVentas() {
      return this.$store.getters["catalogos/gerenciasVentas"]
        ? this.$store.getters["catalogos/gerenciasVentas"]
        : [];
    },
    listaUsuarios() {
      return this.$store.getters["usuarios/listaUsuarios"]
        ? this.$store.getters["usuarios/listaUsuarios"]
        : null;
    },
  },
};
</script>
