import axios from "axios";

const state = {
  ecbs: null,
  ecbSeleccionado: null,
  clientes: [],
  motivos: [],
  gerenciasGenerales: null,
  gerenciasVentas: null,
};

const getters = {
  ecbs: (state) => state.ecbs,
  ecbSeleccionado: (state) => state.ecbSeleccionado,
  clientes: (state) => state.clientes,
  motivos: (state) => state.motivos,
  gerenciasGenerales: (state) => state.gerenciasGenerales,
  gerenciasVentas: (state) => state.gerenciasVentas,
};

const mutations = {
  setEcbs: (state, ecbs) => {
    state.ecbs = ecbs;
  },
  setEcbSeleccionado: (state, ecbSeleccionado) => {
    state.ecbSeleccionado = ecbSeleccionado;
  },
  setClientes: (state, clientes) => {
    state.clientes = clientes;
  },
  setMotivos: (state, motivos) => {
    state.motivos = motivos;
  },
  setGerenciasGenerales: (state, gerenciasGenerales) => {
    state.gerenciasGenerales = gerenciasGenerales;
  },
  setGerenciasVentas: (state, gerenciasVentas) => {
    state.gerenciasVentas = gerenciasVentas;
  },
  inicializar: (state) => {
    state.ecbs = null;
    state.ecbSeleccionado = null;
    state.clientes = [];
  },
};

const actions = {
  async consultarEcbs({ commit }) {
    try {
      let res = await axios.get(`/v1/exclusiones/ecb/catalogo`);
      commit("setEcbs", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarClientesEcb({ commit }, data) {
    try {
      let res = await axios.get(`/v1/exclusiones/ecb/${data?.ecb}/clientes`);
      commit("setClientes", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarMotivosXPilar({ commit }, data) {
    try {
      let res = await axios.get(`/v1/exclusiones/motivo/${data?.pilar}/catalogo`);
      commit("setMotivos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarGerenciasGenerales({ commit }) {
    try {
      let res = await axios.get(`/v1/exclusiones/gerencia-general/catalogo`);
      commit("setGerenciasGenerales", res?.data?.result);
      return res?.data?.result;
    } catch (error) {
      return error;
    }
  },
  async consultarGerenciasVentas({ commit }, data) {
    try {
      let res = await axios.post(
        `/v1/exclusiones/gerencia-ventas/catalogo`, {gerenciaGeneral: data.gerenciaGeneral}
      );
      commit("setGerenciasVentas", res?.data?.result);
    } catch (error) {
      return error;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
