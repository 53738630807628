<template>
  <div class="detail-hours-wrap">
    <div v-for="(item, index)  in datos" :key="index" class="detail-hours">
      {{ item.cantidad }}
    </div>
    <div class="detail-total-general">
      <div class="detail-total-general-arrow"><img :src="this.tipoFlecha" width="20" height="20"></div>
      <div class="detail-total-general-hours">{{ this.sumaHoras }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    datos: {
      type: Object,
      required: true,
    },
    totalHoras: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      sumaHoras: 0,
      tipoFlecha: null
    }
  },
  created() {

    this.datos.map((item) => {
      this.sumaHoras += item.cantidad;
    });

    // Calcular el tipo de flecha de acuerdo a la suma de horas en base a porcentajes
    let porc = ((this.sumaHoras * 100) / this.totalHoras).toFixed(2);
    if (porc >= 0 && porc <= 15) {
      this.tipoFlecha = require("../../../assets/images/red-arrow.png");
    }

    if (porc >= 15.01 && porc <= 30) {
      this.tipoFlecha = require("../../../assets/images/yellow-arrow.png");
    }

    if (porc >= 30.01 && porc <= 100) {
      this.tipoFlecha = require("../../../assets/images/green-arrow.png");
    }
  }
}
</script>
<style>
  .detail-hours-wrap {
    display: flex; 
    flex-wrap: wrap; 
    width: 130%
  }
  .detail-hours {
    width: 80px; 
    border: 1px solid #ccc; 
    padding: 10px; 
    text-align: right; 
    font-size: 10px;
  }
  .detail-total-general {
    width: 80px; 
    border: 1px solid #ccc; 
    padding: 10px; 
    font-size: 10px;
  }
  .detail-total-general-arrow {
    text-align: left; 
    width: 50%; 
    float: left;
  }
  .detail-total-general-hours {
    text-align: right; 
    width: 50%; 
    float: left;
  }
</style>
