<template>
  <div
    class="col-md-8 col-sm-12 offset-lg-2 offset-sm-0 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-sample">
              <div class="section-heading">
                <h4 class="card-title">
                  Agregar preguntas en encuesta:
                  {{ encuestaSeleccionada.nombre }}
                </h4>
              </div>
              <Form
                @submit="submit"
                :validation-schema="formValidation"
                v-slot="{ errors }"
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Tipo pregunta *</label>
                      <fieldset>
                        <Field
                          as="select"
                          name="tipoPregunta"
                          class="form-control"
                          placeholder="Tipo pregunta"
                          v-model="formData.tipoPregunta"
                        >
                          <option value="" disabled>
                            Seleccione un tipo de pregunta
                          </option>
                          <option
                            v-for="item in tiposPregunta"
                            :key="item.id"
                            :value="item.descripcion"
                          >
                            {{ item.descripcion }}
                          </option>
                        </Field>
                        <MensajeValidacion :mensaje="errors.dependeDe" />
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="nombre">Enunciado *</label>
                      <fieldset>
                        <Field
                          type="text"
                          name="enunciado"
                          id="enunciado"
                          class="form-control"
                          placeholder="Enunciado"
                          v-model.trim="formData.enunciado"
                        />
                        <MensajeValidacion :mensaje="errors.enunciado" />
                      </fieldset>
                    </div>
                  </div>
                  <ErrorMessage :mensaje="mensaje" v-if="error" />
                </div>

                <div class="row" v-if="formData.tipoPregunta!=='TEXTO'">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name">Opciones de la pregunta *</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          v-model="formData.opcion"
                          aria-label="Text input with segmented dropdown button"
                          placeholder="Opción"
                        /><input
                          type="text"
                          class="form-control"
                          v-model="formData.ponderacion"
                          aria-label="Text input with segmented dropdown button"
                          placeholder="Ponderación"
                        />
                        <div class="input-group-append">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-general btn-buscar"
                            @click="agregar"
                          >
                            <span class="icon-plus"></span> Agregar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="formData.opciones.length">
                  <div class="col-md-12">
                    <h6>Lista de opciones agregadas</h6>
                    <div
                      class="d-flex mb-1"
                      v-for="item in formData.opciones"
                      :key="item"
                    >
                      <p>
                        Opción: {{ item.opcion }} - Ponderación: {{ item.ponderacion }}
                      </p>
                      <a
                        href="javascript:void(0)"
                        class="ml-3"
                        @click="eliminarOpcion(item)"
                        ><i class="ti-close text-danger"></i
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Depende de</label>
                      <fieldset>
                        <Field
                          as="select"
                          name="dependeDe"
                          class="form-control"
                          placeholder="Depende de"
                          v-model="formData.dependeDe"
                          @change="consultarOpciones"
                        >
                          <option value="" disabled>
                            Seleccione una pregunta
                          </option>
                          <option
                            v-for="item in preguntas"
                            :key="item.id"
                            :value="item.id"
                          >
                            {{ item.enunciado }}
                          </option>
                        </Field>
                        <MensajeValidacion :mensaje="errors.dependeDe" />
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Mostrar si</label>
                      <fieldset>
                        <Field
                          as="select"
                          name="mostrarSi"
                          class="form-control"
                          placeholder="Mostrar si"
                          v-model="formData.mostrarSi"
                        >
                          <option value="" disabled>
                            Seleccione una opción
                          </option>
                          <option
                            v-for="item in opcionesDependeDe"
                            :key="item.opcion"
                            :value="item.opcion"
                          >
                            {{ item.opcion }}
                          </option>
                        </Field>
                        <MensajeValidacion :mensaje="errors.dependeDe" />
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <button
                      type="submit"
                      id="form-submit"
                      class="btn btn-primary mr-2"
                    >
                      Guardar
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="cerrar"
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <ModalMessages
          :mensaje="mensajeProceso"
          titulo="Agregar encuesta"
          :method="regresar"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../components/ErrorMessage.vue";
import ModalMessages from "../../../components/ModalMessages.vue";

export default {
  name: "PreguntasForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
    ModalMessages,
  },
  data() {
    const formValidation = {
      enunciado: "required",
      tipoPregunta: "required",
    };
    return {
      formData: {
        enunciado: "",
        tipoPregunta: "",
        dependeDe: 0,
        mostrarSi: "",
        opcion: null,
        ponderacion: null,
        opciones: [],
      },
      mensaje: "",
      formValidation,
      error: false,
      mensajeProceso: [],
      tiposPregunta: [
        {
          id: 1,
          descripcion: "OPCION_SIMPLE",
        },
        {
          id: 2,
          descripcion: "OPCION_MULTIPLE",
        },
        {
          id: 3,
          descripcion: "TEXTO",
        },
      ],
      opcionesDependeDe: [],
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    consultarOpciones() {
      this.opcionesDependeDe = [];
      const preguntaFinded = this.preguntas.find(
        (item) => item?.id === this.formData.dependeDe
      );
      this.opcionesDependeDe = preguntaFinded?.opciones ?? [];
    },
    agregar() {
      this.formData.opciones.push({opcion:this.formData.opcion, ponderacion: this.formData.ponderacion});
      this.formData.opcion = null;
      this.formData.ponderacion = null;
    },
    async submit() {
      this.mensajeProceso = [];
      this.error = false;
      this.loaderSpinner();
      const data = {
        dependeDe: this.formData.dependeDe,
        encuesta: this.encuestaSeleccionada.id,
        enunciado: this.formData.enunciado,
        mostrarSi: this.formData.mostrarSi,
        opciones: this.formData.opciones,
        tipoPregunta: this.formData.tipoPregunta,
      };
      const resp = await this.$store.dispatch(
        "encuestas/crearPreguntas",
        data
      );
      this.loader.hide();
      if (!resp?.success) {
        this.error = true;
        this.mensajeProceso.push(
          resp?.errorMessage + ", código " + resp?.status
        );
        document.getElementById("modalButton").click();
        return;
      }
      this.mensajeProceso.push("Encuestas: " + resp?.successMessage);
      document.getElementById("modalButton").click();
    },
    eliminarOpcion(opcion) {
      const opcionesFiltradas = this.formData.opciones?.filter(
        (item) => item !== opcion
      );
      this.formData.opciones = opcionesFiltradas;
    },
    regresar() {
      if (!this.error) {
        this.$router.push({ name: "Encuestas" });
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    preguntas() {
      return this.$store.getters["encuestas/preguntas"]
        ? this.$store.getters["encuestas/preguntas"]
        : null;
    },
    encuestaSeleccionada() {
      return this.$store.getters["encuestas/encuestaSeleccionada"]
        ? this.$store.getters["encuestas/encuestaSeleccionada"]
        : null;
    },
  },
};
</script>
