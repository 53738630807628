<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Reporte acumulado</h3>
          </div>
          <div class="col-md-6">
            <button
              @click="exportExcel"
              class="btn btn-primary float-right"
              :disabled="exclusiones.length === 0"
            >
              <span class="icon-download"></span> Exportar a excel
            </button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-3">
            <input type="date" class="form-control" v-model="fechaInicio" />
          </div>
          <div class="col-md-3 mb-2">
            <input type="date" class="form-control" v-model="fechaFin" />
          </div>
          <div class="col-md-3">
            <button
              type="button"
              class="btn btn-secondary"
              @click="consultarXFechas"
              :disabled="fechaInicio && fechaFin ? false : true"
            >
              Consultar
            </button>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-3">
            <label>Estado</label>
            <select class="form-control" v-model="estado">
              <option v-for="item in estados" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-3 ">
            <label>Pilar</label>
            <select class="form-control" v-model="pilar">
              <option v-for="item in pilares" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <div class="table-responsive" @change="consultar">
          <table class="table table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Cliente</th>
                <th>Fecha exclusión</th>
                <th>ECB</th>
                <th>Nombre ECB</th>
                <th>Nombre Gerente General</th>
                <th>Nombre Gerente Ventas</th>
                <th>Tipo</th>
                <th>Motivo</th>
                <th>Motivo del rechazo</th>
                <th>Pilar</th>
                <th>Estado</th>

              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in exclusionesFiltradas"
                :key="item.id"
                @change="consultar"
              >
                <td>
                  <a
                    href="javascript:void(0)"
                    @click="editarEstado(item)"
                    data-toggle="modal"
                    data-target="#ModalEditarEstado"
                    data-backdrop="static"
                    data-keyboard="false"
                    ><i class="ti-pencil"></i></a
                  >&nbsp;
                  <a
                    href="javascript:void(0)"
                    v-if="item?.clientes?.length"
                    @click="seleccionarClientes(item?.clientes ?? [])"
                    data-toggle="modal"
                    data-target="#modalClientes"
                    ><i class="ti-user"></i></a
                  >&nbsp;
                  <a
                    href="javascript:void(0)"
                    @click="consultarAdjuntos(item?.id)"
                    ><i class="ti-cloud-down"></i
                  ></a>
                </td>
                <td>{{ item?.cliente }}</td>
                <td>{{ item?.fechaExclusion }}</td>
                <td>{{ item?.ecb }}</td>
                <td>{{ item?.nombreFfvv }}</td>
                <td>{{ item?.gerenteGeneral }}</td>
                <td>{{ item?.gerenteVentas }}</td>
                <td>{{ item?.tipo }}</td>
                <td>{{ item?.motivo }}</td>
                <td>{{ item?.motivoRechazo }}</td>
                <td>{{ item?.pilar }}</td>
                <td>
                  <div
                    class="badge"
                    :class="
                      item?.estadoExclusion === 'APROBADO'
                        ? 'badge-success'
                        : item?.estadoExclusion === 'PENDIENTE'
                        ? 'badge-secondary'
                        : 'badge-danger'
                    "
                  >
                    {{ item.estadoExclusion }}
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
        <Clientes :listaClientes="clientes" titulo="Clientes" />
        <ModalEditarEstado :guardar="editar" />
        <Adjuntos titulo="Lista de archivos" :listaAdjuntos="adjuntos" />
        <button
          id="adjuntosId"
          type="button"
          data-toggle="modal"
          data-target="#modalAdjuntos"
          v-show="false"
        ></button>
      </div>
    </div>
  </div>
</template>
<script>
import { saveExcel } from "@progress/kendo-vue-excel-export";
import Clientes from "../../../components/Clientes.vue";
import Adjuntos from "../../../components/Adjuntos.vue";
import ModalEditarEstado from "../../../components/ModalEditarEstado.vue";
export default {
  name: "GestionReporteAcumuladoAuditor",
  data() {
    return {
      fechaInicio: "",
      fechaFin: "",
      clientes: [],
      estados: ["TODOS", "APROBADO", "RECHAZADO", "PENDIENTE"],
      estado: "TODOS",
      exclusionesFiltradas: [],
      pilares: ["TODOS", "TECNICO", "RTM", "PEOPLE"],
      pilar: "TODOS",
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.$store.commit("exclusiones/inicializar");
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarXFechas() {
      try {
        this.loaderSpinner();
        const data = {
          fechaInicio: this.fechaInicio,
          fechaFin: this.fechaFin,
        };
        await this.$store.dispatch(
          "exclusiones/consultarReporteAcumuladoAuditorXFechas",
          data
        );
        this.exclusionesFiltradas = this.exclusiones;
        this.filtrar();
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async consultarXEstado(estado) {
      this.loaderSpinner();
      try {
        await this.$store.dispatch(
          "exclusiones/consultarExclusionesByUsuario",
          { estado }
        );
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    exportExcel() {
      saveExcel({
        data: this.exclusiones,
        fileName: "reporte-acumulado",
        columns: [
          { field: "cliente", title: "Cliente" },
          { field: "fechaExclusion", title: "Fecha exclusión" },
          { field: "ecb", title: "Ecb" },
          { field: "nombreFfvv", title: "Nombre Ecb" },
          { field: "gerenteGeneral", title: "Nombre Gerente General" },
          { field: "gerenteVentas", title: "Nombre Gerente Ventas" },
          { field: "tipo", title: "Tipo" },
          { field: "motivo", title: "Motivo" },
          { field: "pilar", title: "Pilar" },
          { field: "estadoExclusion", title: "Estado" },

        ],
      });
    },
    seleccionarClientes(clientes) {
      this.clientes = clientes;
    },
    async consultarAdjuntos(exclusion) {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("adjuntos/consultarAdjuntos", {
          idExclusion: exclusion,
        });
        document.getElementById("adjuntosId").click();
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    editarEstado(item) {
      this.$store.commit("exclusiones/setExclusionSeleccionada", item);
    },
    async editar(data) {
      try {
        await this.$store.dispatch("exclusiones/actualizarEstado", {
          idExclusion: this.exclusionSeleccionada?.id,
          estadoExlusion: data?.estado,
          motivoRechazo: data?.motivo,
        });
        this.consultarXFechas();
      } catch (error) {
        console.log(error);
      }
    },
    filtrar() {
      if (this.estado === "TODOS" && this.pilar === "TODOS") {
        this.exclusionesFiltradas = this.exclusiones;
        return;
      }
      if (this.estado !== "TODOS" && this.pilar === "TODOS") {
        const exclusionesFiltradas = this.exclusiones?.filter(
          (item) => item?.estadoExclusion === this.estado
        );
        if (!exclusionesFiltradas) {
          this.exclusionesFiltradas = [];
        } else {
          this.exclusionesFiltradas = exclusionesFiltradas;
        }
      }
      if (this.estado === "TODOS" && this.pilar !== "TODOS") {
        const exclusionesFiltradas = this.exclusiones?.filter(
          (item) => item?.pilar === this.pilar
        );
        if (!exclusionesFiltradas) {
          this.exclusionesFiltradas = [];
        } else {
          this.exclusionesFiltradas = exclusionesFiltradas;
        }
      }
      if (this.estado !== "TODOS" && this.pilar !== "TODOS") {
        const exclusionesFiltradas = this.exclusiones?.filter(
          (item) =>
            item?.pilar === this.pilar && item?.estadoExclusion === this.estado
        );
        if (!exclusionesFiltradas) {
          this.exclusionesFiltradas = [];
        } else {
          this.exclusionesFiltradas = exclusionesFiltradas;
        }
      }
    },
  },
  computed: {
    exclusiones() {
      return this.$store.getters["exclusiones/exclusiones"]
        ? this.$store.getters["exclusiones/exclusiones"]
        : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    adjuntos() {
      return this.$store.getters["adjuntos/adjuntos"]
        ? this.$store.getters["adjuntos/adjuntos"]
        : [];
    },
    exclusionSeleccionada() {
      return this.$store.getters["exclusiones/exclusionSeleccionada"]
        ? this.$store.getters["exclusiones/exclusionSeleccionada"]
        : [];
    },
  },
  components: { Clientes, Adjuntos, ModalEditarEstado },
};
</script>
<style>
</style>
