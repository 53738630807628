<template>
  <div
    class="modal fade"
    id="ModalEditarEstado"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalEditarEstadoLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ModalEditarEstadoLabel">Modificar el estado</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-md-12">
            <div class="form-group">
              <label>Estado *</label>
              <fieldset>
                <select
                  name="estado"
                  class="form-control"
                  placeholder="ESTADO"
                  v-model="estado"
                  @change="seleccionarEstado"
                >
                  <option value="" disabled>Seleccione el nuevo estado</option>
                  <option
                    v-for="item in estados"
                    :key="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </fieldset>
            </div>
          </div>
          <div class="form-group mt-2" v-if="estado === 'RECHAZADO'">
            <label for="motivo">Motivo</label>
            <textarea
              class="form-control"
              id="motivo"
              rows="2"
              v-model="motivo"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="guardar({estado, motivo})"
            data-dismiss="modal"
            :disabled="!estado.length"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    guardar: { type: Function },
  },
  data() {
    return {
      motivo: "",
      estado: "",
      estados: [
        'APROBADO',
        'RECHAZADO'
      ],
    };
  },
  methods:{
    seleccionarEstado(){
      if(this.estado === 'APROBADO'){
        this.motivo = "";
      }
    }
  },
  computed:{
    exclusionSeleccionada() {
      return this.$store.getters["exclusiones/exclusionSeleccionada"]
        ? this.$store.getters["exclusiones/exclusionSeleccionada"]
        : null;
    },
  },
  watch:{
    exclusionSeleccionada(newExclusion,oldExclusion){
      if(newExclusion!==oldExclusion){
        this.motivo = "";
        this.estado = "";
      }
    }
  }
};
</script>
