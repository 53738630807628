<template>
    <div class="detail-total-hours-wrap">
        <div v-for="(item, index) in totalHoras.hora" :key="index" class="detail-total-hours"
            :style="`background-color: ${this.totalHoras.color[index]}`"
        >
            <strong>{{ item }}</strong>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        datos: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            totalHoras: {
                hora: Array.from({ length: 25 }, () => 0),
                color: Array.from({ length: 24 }, () => null)
            }
        }
    },
    created() {

        // Sumar cantida de horas por columnas
        this.datos.map((element) => {
            element.horas.map((item) => {
                this.totalHoras.hora[item.hora] += item.cantidad;
            });
        });

        // Sumar totales de horas para obtener el total general de la fila
        for (let i=0; i < this.totalHoras.hora.length - 1; i++) {
            this.totalHoras.hora[24] += this.totalHoras.hora[i];
        }

        // Calcular los colores de los totales por columnas en base a porcentajes
        for (let i=0; i < this.totalHoras.hora.length - 1; i++) {
            let porc = ((this.totalHoras.hora[i] * 100) / this.totalHoras.hora[24]).toFixed(2);
            if (porc >= 0 && porc <=15) {
                this.totalHoras.color[i] = '#f56051';
            }

            if (porc >= 15.01 && porc <=30) {
                this.totalHoras.color[i] = '#f5d951';
            }

            if (porc >= 30.01 && porc <=100) {
                this.totalHoras.color[i] = '#74d338';
            }
        }
    }
}
</script>
<style>
  .detail-total-hours {
    width: 80px; 
    border: 1px solid #ccc; 
    padding: 10px; 
    text-align: right; 
    font-size: 10px;
  }
  .detail-title-access {
    border: 1px solid #ccc;
    padding-top: 10px; 
    font-size: 10px;
  }
  .detail-total-hours-wrap {
    display: flex; 
    flex-wrap: wrap; 
    width: 130%
  }
</style>
  