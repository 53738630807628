import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import jwt_decode from "jwt-decode";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineRule, configure } from "vee-validate";
import { required } from "@vee-validate/rules";

defineRule("required", required);

configure({
    generateMessage: (context) => {
      const messages = {
        required: `Campo obligatorio`,
      };
      const message = messages[context.rule.name]
        ? messages[context.rule.name]
        : `El campo ${context.field} no es válido`;
      return message;
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: true,
  });

//PRUEBAS
//axios.defaults.baseURL = "https://scientialabspruebas.xyz/apiteo/api";

//PROD
axios.defaults.baseURL = 'https://leadscncontrol.com/teo/api';

axios.interceptors.request.use(
  (config) => {
    const token = window.sessionStorage.getItem("_token_exclusiones");
    config.headers.Authorization = `Bearer ${token}`;
    if (token != null) {
      if (jwt_decode(token).exp >= Date.now().valueOf() / 1000) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        store.dispatch("auth/logout");
        router.replace("/");
      }
    } else {
      store.dispatch("auth/logout");
      router.replace("/");
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

createApp(App)
  .use(Loading, {
    canCancel: false,
    loader: "spinner",
    color: "#ED3123",
  })
  .use(store)
  .use(router)
  .mount("#app");
