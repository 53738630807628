import axios from "axios";

const state = {
  cortes: null,
  corteSeleccionado: null,
  rango: null,
  mensaje: "",
  mesAnterior: null
};

const getters = {
  cortes: (state) => state.cortes,
  corteSeleccionado: (state) => state.corteSeleccionado,
  rango: (state) => state.rango,
  mesAnterior: (state) => state.mesAnterior,
};

const mutations = {
  setCortes: (state, cortes) => {
    state.cortes = cortes;
  },
  setCorteSeleccionado: (state, corteSeleccionado) => {
    state.corteSeleccionado = corteSeleccionado;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
  setRango: (state, rango) => {
    state.rango = rango;
  },
  setMesAnterior: (state, mesAnterior) => {
    state.mesAnterior = mesAnterior;
  },
  inicializar: (state) => {
    state.cortes = null;
    state.corteSeleccionado = null;
    state.mensaje = "";
    state.mesAnterior = null;
  },
};

const actions = {
  async consultarAll({ commit }) {
    try {
      let res = await axios.get(`/v1/config-cortes`);
      commit("setCortes", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async crear({ commit }, data) {
    try {
      await axios.post(`/v1/config-cortes`, data);
      commit("setMensaje", "Se creó exitosamente el corte");
    } catch (error) {
      return error;
    }
  },
  async consultarMesActual({ commit }) {
    try {
      let res = await axios.get(`/v1/config-cortes/actual`);
      commit("setRango", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async editar({ commit }, data) {
    try {
      await axios.put(`/v1/config-cortes/${data?.idConfigCorte}`, data?.data);
      commit("setMensaje", "Se editó exitosamente el corte");
    } catch (error) {
      return error;
    }
  },
  async activarMesAnterior({ commit }, data) {
    try {
      await axios.put(`/v1/parametros/EXCLUSIONES_MES_ANTERIOR
      `, data);
      commit("setMensaje", "Se guardó correctamente");
    } catch (error) {
      return error;
    }
  },
  async consultarMesAnterior({ commit }) {
    try {
      let res = await axios.get(`/v1/parametros/EXCLUSIONES_MES_ANTERIOR`);
      commit("setMesAnterior", res?.data?.result);
    } catch (error) {
      return error;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
