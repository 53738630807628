<template>
  <div
    class="modal fade"
    id="modalClientes"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalClientesLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <form>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalClientesLabel">
              <strong>{{ titulo }}</strong>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <p v-if="!listaClientes?.length">No existen clientes</p>
                <ul>
                  <li v-for="item in listaClientes" :key="item">
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    titulo: String,
    listaClientes: [Object],
  },
};
</script>
