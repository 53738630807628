<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Pedidos Realizados</h3>
          </div>
          <div class="col-md-6">
            <button
                @click="exportExcel"
                class="btn btn-primary float-right"
                :disabled="pedidos.length === 0"
            >
              <span class="icon-download"></span> Exportar a excel
            </button>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-3">
            <input type="date" class="form-control" v-model="fechaInicio"/>
          </div>
          <div class="col-md-3 mb-2">
            <input type="date" class="form-control" v-model="fechaFin"/>
          </div>
          <div class="col-md-3">
            <button
                type="button"
                class="btn btn-secondary"
                @click="consultarXFechas"
                :disabled="fechaInicio && fechaFin ? false : true"
            >
              Consultar
            </button>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Código Cliente</th>
              <th>Código Material</th>
              <th>Cajas/Cant</th>
              <th>Nombre Material</th>
              <th>Condición Pago</th>
              <th>Tipo Pedido</th>
              <th>Activar DC Cod</th>
              <th>Observación PFN</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="item in pedidos"
                :key="item.id"
            >

              <td>{{ item?.pedido.codigoCliente }}</td>
              <td>{{ item?.codigoMaterial }}</td>
              <td>{{ item?.cantidad }}</td>
              <td>{{ item?.nombreMaterial }}</td>
              <td>{{ item?.pedido.condicionPago }}</td>
              <td>{{ item?.pedido.tipoPedido }}</td>
              <td>{{ item?.dcCod }}</td>
              <td>{{ item?.observacionPfn }}</td>

            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import {saveExcel} from "@progress/kendo-vue-excel-export";

export default {
  name: "Pedidos",
  data() {
    return {
      fechaInicio: "",
      fechaFin: "",

    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }

  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarXFechas() {
      try {
        this.loaderSpinner();
        const data = {
          fechaInicio: this.fechaInicio,
          fechaFin: this.fechaFin,
        };
        await this.$store.dispatch(
            "pedidos/consultar",
            data
        )

      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },

    exportExcel() {
      saveExcel({
        data: this.pedidos,
        fileName: "pedidos",
        columns: [
          {field: "pedido.codigoCliente", title: "Código Cliente"},
          {field: "codigoMaterial", title: "Código Material"},
          {field: "cantidad", title: "cantidad"},
          {field: "nombreMaterial", title: "Nombre Material"},
          {field: "pedido.condicionPago", title: "Condición Pago"},
          {field: "pedido.tipoPedido", title: "Tipo Pedido"},
          {field: "dcCod", title: "Activar DC Cod"},
          {field: "observacionPfn", title: "Observación PFN"},

        ],
      });
    }

  },
  computed: {
    pedidos() {
      return this.$store.getters["pedidos/pedidos"]
          ? this.$store.getters["pedidos/pedidos"]
          : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },

  },
  components: {},
};
</script>
