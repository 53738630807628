import {createRouter, createWebHistory} from "vue-router";
import Login from "../views/auth/Login.vue";
import Inicio from "@/components/Inicio";
import Exclusiones from '../views/exclusiones/Exclusiones.vue';
import ExclusionForm from '../views/exclusiones/components/ExclusionForm.vue';
import Configuracion from '../views/admin/Configuracion.vue';
import CorteForm from '../views/admin/components/CorteForm.vue';
import ExclusionesAdmin from '../views/admin/ExclusionesAdmin.vue';
import ReporteAcumulado from '../views/admin/ReporteAcumulado.vue';
import Usuarios from '../views/admin/usuarios/Usuarios.vue';
import UsuarioForm from '../views/admin/usuarios/components/UsuarioForm.vue';
import Pilares from '../views/admin/pilares/Pilares.vue';
import PilarForm from '../views/admin/pilares/components/PilarForm.vue';
import ExclusionesAprobadasRechazadas from '../views/exclusiones/ExclusionesAprobadasRechazadas.vue';
import ReporteAcumuladoUsuario from '../views/exclusiones/ReporteAcumuladoUsuario.vue';
import ReporteAcumuladoAuditor from '../views/admin/ReporteAcumuladoAuditor.vue';
import Pedidos from "../views/pedidos/Pedidos";
import Logistica from "@/views/logistica/Logistica.vue";
import RutaLogisticaForm from "@/views/logistica/components/RutaLogisticaForm.vue";
import Reportes from "@/views/reportes/Reportes.vue";
import Encuestas from "@/views/encuestas/Encuestas.vue";
import EncuestaForm from "@/views/encuestas/components/EncuestaForm.vue";
import DetalleEncuesta from "@/views/encuestas/components/DetalleEncuesta.vue";
import PreguntasForm from "@/views/encuestas/components/PreguntasForm.vue";

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
    },
    {
        path: "/",
        name: "Inicio",
        component: Inicio,
        meta: {
            auth: true,
        },
        children: [
            {
                path: "exclusiones",
                name: "Exclusiones",
                component: Exclusiones,
                meta: {
                    auth: true,
                },
            },
            {
                path: "manage-exclusion",
                name: "ExclusionForm",
                component: ExclusionForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "configuracion",
                name: "Configuracion",
                component: Configuracion,
                meta: {
                    auth: true,
                },
            },
            {
                path: "manage-corte",
                name: "CorteForm",
                component: CorteForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "exclusiones-admin",
                name: "ExclusionesAdmin",
                component: ExclusionesAdmin,
                meta: {
                    auth: true,
                },
            },
            {
                path: "reporte-acumulado",
                name: "ReporteAcumulado",
                component: ReporteAcumulado,
                meta: {
                    auth: true,
                },
            },
            {
                path: "usuarios",
                name: "Usuarios",
                component: Usuarios,
                meta: {
                    auth: true,
                },
            },
            {
                path: "manage-usuario",
                name: "UsuarioForm",
                component: UsuarioForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "pilares",
                name: "Pilares",
                component: Pilares,
                meta: {
                    auth: true,
                },
            },
            {
                path: "manage-pilar",
                name: "PilarForm",
                component: PilarForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "aprobadas-rechazadas",
                name: "ExclusionesAprobadasRechazadas",
                component: ExclusionesAprobadasRechazadas,
                meta: {
                    auth: true,
                },
            },
            {
                path: "reporte-acumulado-usuario",
                name: "ReporteAcumuladoUsuario",
                component: ReporteAcumuladoUsuario,
                meta: {
                    auth: true,
                },
            },
            {
                path: "reporte-acumulado-auditor",
                name: "ReporteAcumuladoAuditor",
                component: ReporteAcumuladoAuditor,
                meta: {
                    auth: true,
                },
            },

            {
                path: "pedidos",
                name: "Pedidos",
                component: Pedidos,
                meta: {
                    auth: true,
                },
            },
            {
                path: "logistica",
                name: "Logistica",
                component: Logistica,
                meta: {
                    auth: true,
                },
            },
            {
                path: "manage-logistica",
                name: "RutaLogisticaForm",
                component: RutaLogisticaForm,
                meta: {
                    auth: true,
                },
            },

            {
                path: "encuestas",
                name: "Encuestas",
                component: Encuestas,
                meta: {
                    auth: true,
                },
            },
            {
                path: "manage-encuesta",
                name: "EncuestaForm",
                component: EncuestaForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "detalle-encuesta",
                name: "DetalleEncuesta",
                component: DetalleEncuesta,
                meta: {
                    auth: true,
                },
            },
            {
                path: "manage-preguntas",
                name: "PreguntasForm",
                component: PreguntasForm,
                meta: {
                    auth: true,
                },
            },

            {
                path: "reportes",
                name: "Reportes",
                component: Reportes,
                meta: {
                    auth: true,
                },
            },

        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const authUser = window.localStorage.getItem("_token_exclusiones") ? true : false;
    if (to.meta.requiresAuth && !authUser) next({name: "Login"});
    else next();
});

export default router;
