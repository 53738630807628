<template>
  <div>
    <a
      class="dropdown-item"
      href="javascript:void(0)"
      v-for="item in lista"
      :key="item?.id"
      @click="seleccionar(item?.usuario)"
      >{{ item?.usuario }}</a
    >
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    seleccionar: { type: Function },
    lista: Array,
  },
  methods() {},
};
</script>
