<template>
  <div :class="configNavbarToggler">
    <Navbar />
    <div class="container-fluid page-body-wrapper">
      <Sidebar />
      <div class="main-panel">
        <div class="content-wrapper">

            <router-view></router-view>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

export default {
  name: "App",
  components: {
    Navbar,
    Sidebar,
  },
  computed:{
    configNavbarToggler(){
      return this.$store.getters["config/configNavbarToggler"]
        ? "container-scroller sidebar-icon-only"
        : "container-scroller";
    }
  }
};
</script>
