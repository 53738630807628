<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-3">
            <h3 class="font-weight-bold">Gestión de Exclusiones</h3>
          </div>
          <div class="col-md-9">
            <button
              @click="procesar('APROBAR TODOS')"
              type="button"
              class="btn btn-primary float-right"
              data-toggle="modal"
              data-target="#modalConfirmacion"
            >
              <span class="icon-plus"></span> Aprobar todos
            </button>
            <button
              class="btn btn-primary float-right mr-2"
              @click="procesar('RECHAZAR TODOS')"
              type="button"
              data-toggle="modal"
              data-target="#modalMotivo"
            >
              <span class="text-white"></span>X Rechazar todos
            </button>
          </div>

        </div>
        <br />
        <div class="table-responsive" @change="consultar">
          <table class="table table-striped">
            <thead>
              <tr>
                <th width="5%"></th>
                <th>Cliente</th>
                <th>Fecha exclusión</th>
                <th>ECB</th>
                <th>Nombre ECB</th>
                <th>Nombre Gerente General</th>
                <th>Nombre Gerente Ventas</th>
                <th>Tipo</th>
                <th>Motivo</th>
                <th>Pilar</th>
                <th>Estado</th>

              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in exclusiones"
                :key="item.id"
                @change="consultar"
              >
                <td>
                  <a
                    href="javascript:void(0)"
                    @click="seleccionarAprobar(item.id)"
                    data-toggle="modal"
                    data-target="#modalConfirmacion"
                    data-backdrop="static"
                    data-keyboard="false"
                    ><i class="ti-check"></i></a
                  >&nbsp;
                  <a
                    href="javascript:void(0)"
                    @click="seleccionarRechazar(item.id)"
                    data-toggle="modal"
                    data-target="#modalMotivo"
                    ><i class="ti-close"></i></a
                  >&nbsp;
                  <a
                    href="javascript:void(0)"
                    v-if="item?.clientes?.length"
                    @click="seleccionarClientes(item?.clientes ?? [])"
                    data-toggle="modal"
                    data-target="#modalClientes"
                    ><i class="ti-user"></i></a
                  >&nbsp;
                  <a
                    href="javascript:void(0)"
                    @click="consultarAdjuntos(item?.id)"
                    ><i class="ti-cloud-down"></i
                  ></a>
                </td>
                <td>{{ item?.cliente }}</td>
                <td>{{ item?.fechaExclusion }}</td>
                <td>{{ item?.ecb }}</td>
                <td>{{ item?.nombreFfvv }}</td>
                <td>{{ item?.gerenteGeneral }}</td>
                <td>{{ item?.gerenteVentas }}</td>
                <td>{{ item?.tipo }}</td>
                <td>{{ item?.motivo }}</td>
                <td>{{ item?.pilar }}</td>
                <td>
                  <div
                    class="badge"
                    :class="
                      item?.estadoExclusion === 'APROBADO'
                        ? 'badge-success'
                        : item?.estadoExclusion === 'PENDIENTE'
                        ? 'badge-secondary'
                        : 'badge-danger'
                    "
                  >
                    {{ item.estadoExclusion }}
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
        <button
          type="button"
          class="btn btn-primary mt-2 mr-2"
          @click="descargar"
          v-if="pilarUsuario === 'RTM'"
        >
          <span class="icon-download"></span> Descargar
        </button>
        <button
          type="button"
          class="btn btn-primary mt-2"
          data-toggle="modal"
          data-target="#modalCargarArchivo"
          v-if="pilarUsuario === 'RTM'"
        >
          <span class="icon-upload"></span> Procesar archivo
        </button>
        <Modal :mensaje="mensaje" :method="procesarSeleccionados" />
        <ModalMotivo :mensaje="mensaje" :guardar="procesarSeleccionados" />
        <CargarArchivo titulo="Cargar archivo" :cargar="procesarArchivo" :fecha="new Date()"/>
        <Clientes :listaClientes="clientes" titulo="Clientes" />
        <Adjuntos titulo="Lista de archivos" :listaAdjuntos="adjuntos" />
        <button
          id="adjuntosId"
          type="button"
          data-toggle="modal"
          data-target="#modalAdjuntos"
          v-show="false"
        ></button>
      </div>
    </div>
  </div>
</template>
<script>
import { saveExcel } from "@progress/kendo-vue-excel-export";
import Modal from "../../../components/Modal.vue";
import ModalMotivo from "../../../components/ModalMotivo.vue";
import Clientes from "../../../components/Clientes.vue";
import Adjuntos from "../../../components/Adjuntos.vue";
import CargarArchivo from "../../../components/CargarArchivo.vue";
export default {
  name: "GestionExclusionesAdmin",
  data() {
    return {
      tipoProceso: "",
      mensaje: "",
      id: 0,
      clientes: [],
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("exclusiones/consultar", {
          pilar: this.pilarUsuario,
          estado: "PENDIENTE",
        });
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    seleccionarClientes(clientes) {
      this.clientes = clientes;
    },
    procesar(tipoProceso) {
      this.tipoProceso = tipoProceso;
      if (tipoProceso === "APROBAR") {
        this.mensaje =
          "Se realizará la aprobación de todas las exclusiones. Está seguro?";
        return;
      }
      if (tipoProceso === "RECHAZAR") {
        this.mensaje = "Se rechazará todas las exclusiones. Está seguro?";
        return;
      }
    },
    seleccionarRechazar(id) {
      this.tipoProceso = "RECHAZAR";
      this.id = id;
      this.mensaje = "Se rechazará la exclusión. Está seguro?";
    },
    seleccionarAprobar(id) {
      this.tipoProceso = "APROBAR";
      this.id = id;
      this.mensaje = "Se realizará la aprobación de la exclusión. Está seguro?";
    },
    procesarSeleccionados(data) {
      if (this.tipoProceso === "APROBAR") {
        this.aprobar();
        return;
      }
      if (this.tipoProceso === "RECHAZAR") {
        this.rechazar(data);
        return;
      }
      if (this.tipoProceso === "APROBAR TODOS") {
        this.aprobarTodos();
        return;
      }
      if (this.tipoProceso === "RECHAZAR TODOS") {
        this.rechazarTodos(data);
        return;
      }
    },
    async aprobarTodos() {
      const data = this.exclusiones?.map((item) => item?.id);
      this.loaderSpinner();
      try {
        await this.$store.dispatch("exclusiones/aprobarTodos", data);
        this.loader.hide();
        this.consultar();
      } catch (error) {
        console.log(error);
        this.loader.hide();
      } /* finally {
        this.loader.hide();
      } */
    },
    async rechazarTodos(motivo) {
      const data = {
        ids: this.exclusiones?.map((item) => item?.id),
        motivo: motivo,
      };
      this.loaderSpinner();
      try {
        await this.$store.dispatch("exclusiones/rechazarTodos", data);
        this.loader.hide();
        this.consultar();
      } catch (error) {
        console.log(error);
      }
    },
    async aprobar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("exclusiones/aprobar", {
          idExclusion: this.id,
        });
        this.loader.hide();
        this.consultar();
      } catch (error) {
        console.log(error);
        this.loader.hide();
      }
    },
    async rechazar(motivo) {
      const data = {
        idExclusion: this.id,
        motivo: {
          motivo,
        },
      };
      this.loaderSpinner();
      try {
        await this.$store.dispatch("exclusiones/rechazar", data);
        this.loader.hide();
        this.consultar();
      } catch (error) {
        console.log(error);
        this.loader.hide();
      }
    },
    async consultarAdjuntos(exclusion) {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("adjuntos/consultarAdjuntos", {
          idExclusion: exclusion,
        });
        document.getElementById("adjuntosId").click();
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    descargar() {
      saveExcel({
        data: this.exclusiones,
        fileName: "exclusiones",
        columns: [
          { field: "id", title: "Id" },
          { field: "cliente", title: "Cliente" },
          { field: "fechaExclusion", title: "Fecha exclusión" },
          { field: "ecb", title: "Ecb" },
          { field: "nombreFfvv", title: "Nombre Ecb" },
          { field: "gerenteGeneral", title: "Nombre Gerente General" },
          { field: "gerenteVentas", title: "Nombre Gerente Ventas" },
          { field: "tipo", title: "Tipo" },
          { field: "motivo", title: "Motivo" },
          { field: "pilar", title: "Pilar" },
          { field: "estadoExclusion", title: "Estado" },
          { field: "-", title: "Motivo rechazo" },
          { field: "-", title: "Estado proceso" },
        ],
      });
    },
    async procesarArchivo(data) {
      this.loaderSpinner();
      try {
        const formData = new FormData();
        formData.append("file", data?.archivo);
        const payload = {
          formData,
          pilar: this.pilarUsuario,
          estado: "PENDIENTE",
        }
        await this.$store.dispatch("exclusiones/procesarExcel", payload);
      } catch (error) {
        this.error = true;
      } finally {
        this.loader.hide();
      }
    },
  },
  computed: {
    exclusiones() {
      return this.$store.getters["exclusiones/exclusiones"]
        ? this.$store.getters["exclusiones/exclusiones"]
        : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    pilarUsuario() {
      return this.$store.getters["pilares/pilarUsuario"]
        ? this.$store.getters["pilares/pilarUsuario"]
        : {};
    },
    adjuntos() {
      return this.$store.getters["adjuntos/adjuntos"]
        ? this.$store.getters["adjuntos/adjuntos"]
        : [];
    }
  },
  components: { Modal, ModalMotivo, Clientes, Adjuntos, CargarArchivo },
};
</script>
