<template>
  <div
    class="modal fade"
    id="modalMotivo"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalMotivoLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalMotivoLabel">Advertencia</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{ mensaje }}
          <div class="form-group mt-2">
            <label for="motivo">Motivo</label>
            <textarea
              class="form-control"
              id="motivo"
              rows="2"
              v-model="motivo"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="guardar(motivo)"
            data-dismiss="modal"
            :disabled="!motivo.length"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    guardar: { type: Function },
    mensaje: String,
  },
  data(){
    return{
      motivo: ""
    }
  }
};
</script>
