import axios from "axios";

const state = {
    tabla: null,
};

const getters = {
    tabla: (state) => state.tabla,
};

const mutations = {
    setTabla: (state, tabla) => {
        state.tabla = tabla;
    },
};

const actions = {
    async consultar({commit}, data) {
        try {
            let res = await axios.post(`/v2/bitacora/utilizacion`, data);
            commit("setTabla", res?.data?.result);
            return {success: true, data: res.data.result};
        } catch (error) {
            return {success: false, error: error.response.data, status: error.response.status};
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
