<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo">
                <img
                  src="../../assets/images/logo-cerveceria-nacional.png"
                  alt="logo"
                />
              </div>
              <h4>Bienvenido!</h4>
              <h6 class="font-weight-light">Ingrese sus credenciales.</h6>
              <form @submit.prevent="login" class="pt-3">
                <div class="form-group">
                  <input
                    type="text"
                    v-model="formData.usuario"
                    class="form-control form-control-lg"
                    id="exampleInputEmail1"
                    placeholder="Username"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    v-model="formData.clave"
                    class="form-control form-control-lg"
                    id="exampleInputPassword1"
                    placeholder="Password"
                  />
                </div>
                <p class="text-danger text-center" v-if="error">
                  Revise el usuario o contraseña
                </p>
                <div class="mt-3">
                  <button
                    class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    type="submit"
                  >
                    LOGIN
                  </button>
                </div>
                <div
                  class="my-2 d-flex justify-content-between align-items-center"
                >
                  <div class="form-check"></div>
                  <a href="#" class="auth-link text-black">Olvidó su clave?</a>
                </div>
                <div class="text-center"><small>v 2.6 </small></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>
<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      formData: {},
      error: false,
    };
  },
  methods: {
    async login() {
      this.error = false;
      try {
        const resp = await this.$store.dispatch("auth/login", this.formData);
        await this.$store.dispatch("pilares/consultarByUsuario");

        const { error } = resp;
        if (error) {
          this.error = true;
          this.$router.push({ name: "Login" });
          return;
        }
        if (resp?.data?.rol === "ROLE_USER") {
          this.$router.replace({ name: "Exclusiones" });
          return;
        }
        if (resp?.data?.rol === "ROLE_AUDITOR") {
          this.$router.replace({ name: "ExclusionesAdmin" });
          return;
        }
        if (resp?.data?.rol === "ROLE_ADMIN_EXCLUSION") {
          this.$router.replace({ name: "Usuarios" });
          return;
        }
        if (resp?.data?.rol === "ROLE_KKAA_HE") {
          this.$router.replace({ name: "Exclusiones" });
          return;
        }
        if (resp?.data?.rol === "ROLE_GERENTE_SERINSE") {
          this.$router.replace({ name: "Exclusiones" });
          return;
        }

        if (resp?.data?.rol === "ROLE_ADMIN_PEDIDOS") {
          this.$router.replace({ name: "Pedidos" });
          return;
        }

        if (resp?.data?.rol === "ROLE_ADMIN_LOGISTICA") {
          this.$router.replace({ name: "Logistica" });
          return;
        }
        if (resp?.data?.rol === "ROLE_REPORTS") {
          this.$router.replace({ name: "Reportes" });
          return;
        }

      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
