<template>
  <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div
      class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
    >
      <a class="navbar-brand brand-logo mr-5" href="index.html"
        ><img
          src="../assets/images/logo-cerveceria-nacional.png"
          class="mr-2"
          alt="logo"
      /></a>
      <a class="navbar-brand brand-logo-mini" href="index.html"
        ><img src="../assets/images/cn-logo.png" alt="logo"
      /></a>
    </div>
    <div
      class="navbar-menu-wrapper d-flex align-items-center justify-content-end"
    >
      <button
        class="navbar-toggler navbar-toggler align-self-center"
        type="button"
        data-toggle="minimize"
        @click="actualizarNavbarToggler"
      >
        <span class="icon-menu"></span>
      </button>
      <ul class="navbar-nav mr-lg-2">
        <li class="nav-item nav-search d-none d-lg-block">
          <!--<div class="input-group">
            <div class="input-group-prepend hover-cursor" id="navbar-search-icon">
                <span class="input-group-text" id="search">
                  <i class="icon-search"></i>
                </span>
            </div>
            <input type="text" class="form-control" id="navbar-search-input" placeholder="Buscar" aria-label="search"
                   aria-describedby="search" @input="filtrarTabla($event)" v-model="codigo">
          </div>-->
        </li>
      </ul>
      <ul class="navbar-nav navbar-nav-right">
        <li class="nav-item nav-profile dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            data-toggle="dropdown"
            id="profileDropdown"
          >
            <i class="ti-user"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right navbar-dropdown"
            aria-labelledby="profileDropdown"
          >
            <a @click="cambiarClave" class="dropdown-item">
              <i class="ti-lock text-primary"></i>
              Cambiar clave
            </a>
            <a @click="logout" class="dropdown-item">
              <i class="ti-power-off text-primary"></i>
              Logout
            </a>
          </div>
        </li>
        <!-- <li class="nav-item nav-profile dropdown">
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
              <a class="dropdown-item">
                <i class="ti-settings text-primary"></i>
                Settings
              </a>
              <a class="dropdown-item">
                <i class="ti-power-off text-primary"></i>
                Logout
              </a>
            </div>
        </li> -->
        <!--<li class="nav-item nav-settings d-none d-lg-flex">
          <a class="nav-link" href="#">
            <i class="icon-ellipsis"></i>
          </a>
        </li>-->
      </ul>
      <button
        @click="mobileNav"
        class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
        type="button"
        data-toggle="offcanvas"
      >
        <span class="icon-menu"></span>
      </button>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      codigo: "",
      mostrarMobileNav: false,
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
    },
    async cambiarClave() {
      this.$router.push({ name: "CambiarClaveForm" });
    },
    mobileNav() {
      if (!this.mostrarMobileNav) {
        this.openMobileNav();
      } else {
        this.closeMobileNav();
      }
    },
    openMobileNav() {
      var root = document.getElementById("sidebar"); // '0' to assign the first (and only `HTML` tag)
      root.className += " active";
      this.mostrarMobileNav = true;
    },
    closeMobileNav() {
      var root = document.getElementById("sidebar"); // '0' to assign the first (and only `HTML` tag)
      root.classList.remove("active");
      this.mostrarMobileNav = false;
    },
    actualizarNavbarToggler() {
      const estadoConfigNavbar =
        this.$store.getters["config/configNavbarToggler"];
      this.$store.commit("config/setConfigNavbarToggler", !estadoConfigNavbar);
    },
  },
};
</script>
<style></style>
