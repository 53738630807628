<template>
    <div class="row total-general-main-color">
        <div class="col-md-2 total-title">
            <strong>Total general</strong>
        </div>
        <div class="col-md-2 total-title-access"></div>
        <div class="col-md-8 pl-0">
            <div class="total-hours-wrap">
                <div v-for="(item, index)  in sumaHoras" :key="index" class="total-hours">
                    <strong>{{ item }}</strong>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        tabla: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            sumaHoras: Array.from({ length: 25 }, () => 0)
        }
    },
    created() {

        // Sumar cantida de horas por columnas
        this.tabla.map((element) => {
            element.accesos.map((acceso) => {
                acceso.horas.map((item) => {
                    this.sumaHoras[item.hora] += item.cantidad;
                })
            });
        });

        // Sumar totales de horas para obtener el total general de la fila
        for (let i = 0; i < this.sumaHoras.length - 1; i++) {
            this.sumaHoras[24] += this.sumaHoras[i];
        }
    }
}
</script>
<style>
  .total-general-main-color {
    background-color: #F0F0F0;
  }
  .total-title {
    border: 1px solid #ccc; 
    padding: 10px; 
    padding-left: 20px; 
    font-size: 12px;
  }
  .total-title-access {
    border: 1px solid #ccc; 
    padding: 10px;
  }
  .total-hours-wrap {
    display: flex; 
    flex-wrap: wrap; 
    width: 130%
  }
  .total-hours {
    background-color: #F0F0F0; 
    width: 80px; 
    border: 1px solid #ccc; 
    padding: 10px; 
    text-align: right; 
    font-size: 12px;
  }
</style>