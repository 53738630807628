<template>
  <router-view />
  <button
    type="button"
    v-show="false"
    id="modalButton"
    class="btn btn-primary"
    data-toggle="modal"
    data-target="#modalMessages"
    data-backdrop="static"
    data-keyboard="false"
  >
    Modal messages
  </button>
  <button
    type="button"
    v-show="false"
    id="modalButtonConfirmacion"
    class="btn btn-primary"
    data-toggle="modal"
    data-target="#modalConfirmacion"
    data-backdrop="static"
    data-keyboard="false"
  >
    Modal confirmación
  </button>
</template>

<style>
@import "assets/css/feather.css";
@import "assets/css/themify-icons.css";
@import "assets/css/vendor.bundle.base.css";
@import "assets/css/dataTables.bootstrap4.css";
@import "assets/css/select.dataTables.min.css";
@import "assets/css/style.css";
</style>