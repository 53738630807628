<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Gerencia Usuario</h3>
          </div>
          <div class="col-md-6">
            <button @click="agregar" class="btn btn-primary float-right">
              <span class="icon-plus"></span> Agregar
            </button>
          </div>
        </div>
        <br />
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th></th>

                <th>Usuario</th>
                <th>Rol</th>
                <th>Gerencia General</th>
                <th>Gerencia ventas</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in usuarios" :key="item.id">
                <td>
                  <a href="javascript:void(0)" @click="editar(item)"
                    ><i class="ti-pencil"></i></a
                  ><!-- &nbsp;
                  <a href="javascript:void(0)" @click="eliminar(item.id)"
                    ><i class="ti-trash"></i
                  ></a> -->
                </td>

                <td>{{ item?.usuario }}</td>
                <td>{{ item?.rol }}</td>
                <td>{{ item?.gerenciaGeneral }}</td>
                <td>{{ item?.gerenciaVentas }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GestionUsuarios",
  data() {
    return {};
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("usuarios/consultarAll");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      await this.$store.commit("usuarios/setUsuarioSeleccionado", null);
      this.$router.push({ name: "UsuarioForm" });
    },
    async editar(usuario) {
      await this.$store.commit("usuarios/setUsuarioSeleccionado", usuario);
      this.$router.push({ name: "UsuarioForm" });
    },
    async eliminar(id) {
        console.log(id)

    },
  },
  computed: {
    usuarios() {
      return this.$store.getters["usuarios/usuarios"]
        ? this.$store.getters["usuarios/usuarios"]
        : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
  },
};
</script>
