<template>
  <div class="card">
    <div class="card-body" v-if="isLogged">
      <div class="col-md-12 col-sm-12 ">
        <h3 class="font-weight-bold">Reporte Interaccion de Usuarios</h3>
        <div class="row">
          <div class="col-md-3">
            <input type="date" class="form-control" v-model="fechaInicio"/>
          </div>
          <div class="col-md-3">
            <input type="date" class="form-control" v-model="fechaFin"/>
          </div>
          <div class="col-md-3">
            <button @click="consultar" class="btn btn-primary float-left">
              <span class="icon-search"></span> Consultar
            </button>
          </div>

        </div>

        <br/>
        <div class="main-content">
          <div class="second-content">
            <Header v-if="checkTable"/>
            <div v-for="(item, index) in tabla" :key="index">
              <DetalleAcceso :tipoConsulta="item.tipoConsulta" :datos="item.accesos"/>
              <TotalAccesoHoras :item="item"/>
            </div>
            <TotalGeneralHoras :tabla="tabla" v-if="checkTable"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DetalleAcceso from "@/views/reportes/components/DetalleAcceso.vue";
import Header from "@/views/reportes/components/Header.vue";
import TotalAccesoHoras from "@/views/reportes/components/TotalAccesoHoras.vue";
import TotalGeneralHoras from "@/views/reportes/components/TotalGeneralHoras.vue";

export default {
  components: {DetalleAcceso, Header, TotalAccesoHoras, TotalGeneralHoras},
  data() {
    return {
      tabla: null,
      horas: Array.from({length: 24}, (value, index) => index),
      fechaInicio: null,
      fechaFin: null
    }
  },
  methods: {
    async consultar() {

      const resp = await this.$store.dispatch("bitacora/consultar", {
        fechaInicial: this.fechaInicio,
        fechaFinal: this.fechaFin
      });
      if (resp.success) {
        this.tabla = resp.data
      } else {
        alert(resp.error.errors[0])
      }
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    checkTable() {
      return this.tabla != null ? true : false;
    }
  }
}
</script>
<style>
.main-content {
  overflow-x: auto;
  width: 100%;
}

.second-content {
  width: max-content;
  margin-top: 50px;
}
</style>
