import {createStore} from 'vuex'
import auth from "./modules/auth";
import exclusiones from "./modules/exclusiones";
import catalogos from "./modules/catalogos";
import cortes from "./modules/cortes";
import usuarios from "./modules/usuarios";
import pilares from "./modules/pilares";
import adjuntos from "./modules/adjuntos";
import config from "./modules/config";
import pedidos from './modules/pedidos'
import logistica from './modules/logistica'
import bitacora from './modules/bitacora'
import encuestas from './modules/encuestas'

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        exclusiones,
        catalogos,
        cortes,
        usuarios,
        pilares,
        adjuntos,
        config,
        pedidos,
        logistica,
        bitacora,
        encuestas
    }
})
