<template>
  <div
    class="col-md-8 col-sm-12 offset-lg-2 offset-sm-0 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-sample">
              <div class="section-heading">
                <h4 class="card-title">
                  {{
                    pilarSeleccionado ? "Editar pilar-usuario" : "Agregar pilar-usuario"
                  }}
                </h4>
              </div>
              <Form
                @submit="submit"
                :validation-schema="formValidation"
                v-slot="{ errors }"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="usuario">Usuario *</label>
                          <fieldset>
                            <Field
                              type="text"
                              name="usuario"
                              id="usuario"
                              class="form-control"
                              placeholder="Usuario"
                              v-model.trim="formData.usuario"
                              :disabled="pilarSeleccionado?.usuario"
                              @keyup="consultarUsuario"
                            />
                            <MensajeValidacion :mensaje="errors.usuario" />
                          </fieldset>
                          <ListaUsuarios :lista="listaUsuarios" :seleccionar="seleccionarUsuario"/>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Pilar *</label>
                          <fieldset>
                            <Field
                              as="select"
                              name="pilar"
                              class="form-control"
                              placeholder="Pilar"
                              v-model.trim="formData.pilar"
                            >
                              <option value="" disabled>
                                Seleccione un pilar
                              </option>
                              <option
                                v-for="item in pilares"
                                :key="item"
                                :value="item"
                              >
                                {{ item }}
                              </option>
                            </Field>
                            <MensajeValidacion :mensaje="errors.pilar" />
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage :mensaje="mensaje" v-if="error" />
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <button
                      type="submit"
                      id="form-submit"
                      class="btn btn-primary mr-2"
                    >
                      Guardar
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="cerrar"
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";
import ListaUsuarios from "../../../../components/ListaUsuarios.vue";

export default {
  name: "PilarForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
    ListaUsuarios
},
  data() {
    const formValidation = {
      usuario: "required",
      pilar: "required",
    };
    return {
      formData: {
        usuario: "",
        pilar: "",
      },
      mensaje: "",
      formValidation,
      error: false,
      pilares: ["TECNICO", "RTM", "PEOPLE"],
    };
  },
  mounted() {
    if (this.pilarSeleccionado) {
      this.formData = { ...this.pilarSeleccionado };
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarUsuario(e){      
      const filtro = e.target.value;
      if(filtro?.length < 1){
        this.$store.commit("usuarios/setListaUsuarios",[]);
        return;
      }
      try {
        await this.$store.dispatch("usuarios/consultarUsuarios", {usuario: filtro})
      } catch (error) {
        console.log(error)
      }
    },
    seleccionarUsuario(usuario){
      this.formData.usuario = usuario;
      this.$store.commit("usuarios/setListaUsuarios",[]);
    },
    async submit() {
      this.error = false;
      this.loaderSpinner();
      try {
        if (this.pilarSeleccionado) {
          await this.$store.dispatch("pilares/editar", {
            idPilarUsuario: this.pilarSeleccionado.id,
            data: this.formData,
          });
          await this.$store.commit("pilares/setPilarSeleccionado", null);
        } else {
          await this.$store.dispatch("pilares/crear", this.formData);
        }
        this.$router.push({ name: "Pilares" });
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {
    pilarSeleccionado() {
      return this.$store.getters["pilares/pilarSeleccionado"]
        ? this.$store.getters["pilares/pilarSeleccionado"]
        : null;
    },
    listaUsuarios() {
      return this.$store.getters["usuarios/listaUsuarios"]
        ? this.$store.getters["usuarios/listaUsuarios"]
        : null;
    },
  },
};
</script>
