import axios from "axios";

const state = {
    pedidos: null
};

const getters = {
    pedidos: (state) => state.pedidos,
};

const mutations = {
    setPedidos: (state, pedidos) => {
        state.pedidos = pedidos;
    }
};

const actions = {
    async consultar({commit}, data) {
        try {
            let res = await axios.post(
                `/v1/pedidos/fecha-inicial/${data.fechaInicio}/fecha-final/${data.fechaFin}`
            );
            commit("setPedidos", res?.data?.result);
        } catch (error) {
            return error;
        }
    }


};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
