<template>
  <div
    class="modal fade"
    id="modalMessages"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalMessagesLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalMessagesLabel">
            <strong>{{ titulo }}</strong>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="method"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ul>
            <li v-for="(item, $index) in mensaje" :key="$index">
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="method">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    method: { type: Function },
    mensaje: Array,
    titulo: String,
  },
};
</script>
