<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-3">
            <h3 class="font-weight-bold">Gestión de Cortes</h3>
          </div>
          <div class="col-md-9">
            <button @click="agregar" class="btn btn-primary float-right">
              <span class="icon-plus"></span> Agregar
            </button>
          </div>
        </div>
        <br />
        <div class="custom-control custom-switch">
          <input
            type="checkbox"
            class="custom-control-input"
            id="customSwitch1"
            @change="activarMesAnterior"
            :checked="mesAnteior === 'SI' ? true : false"
          />
          <label class="custom-control-label" for="customSwitch1"
            >Activar mes anterior</label
          >
        </div>
        <br />
        <div class="table-responsive" @change="consultar">
          <table class="table table-striped">
            <thead>
              <tr>
                <th width="5%"></th>
                <th>Año</th>
                <th>Mes</th>
                <th>Corte 1</th>
                <th>Corte 2</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in cortes" :key="item.id" @change="consultar">
                <td>
                  <a href="javascript:void(0)" @click="editar(item)"
                    ><i class="ti-pencil"></i
                  ></a>
                </td>
                <td>{{ item?.anio }}</td>
                <td>{{ getMes(item?.mes) }}</td>
                <td>
                  {{ item?.diaInicialCorte1 }} al {{ item?.diaFinalCorte1 }}
                </td>
                <td>
                  {{ item?.diaInicialCorte2 }} al {{ item?.diaFinalCorte2 }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GestionExclusiones",
  data() {
    return {
      meses: [
        { id: 1, descripcion: "ENERO" },
        { id: 2, descripcion: "FEBRERO" },
        { id: 3, descripcion: "MARZO" },
        { id: 4, descripcion: "ABRIL" },
        { id: 5, descripcion: "MAYO" },
        { id: 6, descripcion: "JUNIO" },
        { id: 7, descripcion: "JULIO" },
        { id: 8, descripcion: "AGOSTO" },
        { id: 9, descripcion: "SEPTIEMBRE" },
        { id: 10, descripcion: "OCTUBRE" },
        { id: 11, descripcion: "NOVIEMBRE" },
        { id: 12, descripcion: "DICIEMBRE" },
      ],
      valor: ""
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar();
    this.consultarMesAnterior();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("cortes/consultarAll");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    getMes(mes) {
      const mesEncontrado = this.meses?.find((item) => item?.id === mes);
      return mesEncontrado?.descripcion || "";
    },
    async consultarMesAnterior() {
      try {
        await this.$store.dispatch("cortes/consultarMesAnterior");
      } catch (error) {
        alert("No se pudo realizar la consulta del mes anterior");
      }
    },
    async agregar() {
      this.$router.push({ name: "CorteForm" });
    },
    async editar(corte) {
      await this.$store.commit("cortes/setCorteSeleccionado", corte);
      this.$router.push({ name: "CorteForm" });
    },
    async activarMesAnterior(valor) {
      console.log(valor?.target?.checked);
      try {
        await this.$store.dispatch("cortes/activarMesAnterior", {valor: valor?.target?.checked ? "SI" : "NO"});
        //alert("Se guardó correctamente");
      } catch (error) {
        alert("No se pudo guardar la información");
      }
    },
    async eliminar(id) {
      console.log(id);
      /* this.$swal({
        text: "¿Está seguro de eliminar la información?",
        //icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
            await this.$store.dispatch("usuarios/eliminar", { id: id });
            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
        }
      }); */
    },
  },
  computed: {
    cortes() {
      return this.$store.getters["cortes/cortes"]
        ? this.$store.getters["cortes/cortes"]
        : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    mesAnteior() {
      return this.$store.getters["cortes/mesAnterior"]
        ? this.$store.getters["cortes/mesAnterior"]?.valor
        : [];
    }
  },
};
</script>
