<template>
    <div class="row">
        <div class="col-md-2 total-title-type">
            <strong>Total {{ item.tipoConsulta }}</strong>
        </div>
        <div class="col-md-2 total-title-access"></div>
        <div class="col-md-8 pl-0">
            <TotalHoras :datos="item.accesos" />
        </div>
    </div>
</template>
<script>
import TotalHoras from "@/views/reportes/components/TotalHoras.vue";

export default {
    components: { TotalHoras },
    props: {
        item: {
            type: Object,
            required: true,
        }
    }
}
</script>
<style>
  .total-title-type {
    border: 1px solid #ccc; 
    padding-top: 10px; 
    padding-left: 20px; 
    font-size: 10px;
  }
  .total-title-access {
    border: 1px solid #ccc; 
    padding-top: 10px;
  }
</style>