<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-3">
            <h3 class="font-weight-bold">
              Encuesta: {{ encuestaSeleccionada.nombre }}
            </h3>
          </div>
          <div class="col-md-9">
            <button @click="agregar" class="btn btn-primary float-right">
              <span class="icon-plus"></span> Agregar pregunta
            </button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th>Pregunta</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in preguntas" :key="item.id">
                    <td width="100">
                      <a href="javascript:void(0)" @click="eliminar(item.id)"
                        ><i class="ti-trash"></i
                      ></a>
                    </td>
                    <td>
                      {{ item.enunciado }}
                    </td>
                    <td>
                      <ul v-for="opcion in item.opciones" :key="opcion.opcion">
                        <li>
                          Opción: {{ opcion.opcion }} - Ponderación:
                          {{ opcion.ponderacion }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Modal :mensaje="mensaje" :method="confirmarEliminar" />
        <ModalMessages
          :mensaje="mensajeProceso"
          titulo="Eliminar pregunta"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ModalMessages from "../../../components/ModalMessages.vue";
import Modal from "../../../components/Modal.vue";
export default {
  name: "DetalleEncuesta",
  components: {
    ModalMessages,
    Modal
  },
  data() {
    return {
      mensajeProceso: [],
      mensaje: "",
      idPreguntaSeleccionada: 0
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async agregar() {
      this.$router.push({ name: "PreguntasForm" });
    },
    eliminar(id){
        this.idPreguntaSeleccionada = id;
        this.mensaje = "¿Desea eliminar la información?";
        document.getElementById("modalButtonConfirmacion").click();
    },
    async confirmarEliminar() {
      this.mensajeProceso = [];
      this.mensaje = "";
      this.error = false;
      this.loaderSpinner();
      const resp = await this.$store.dispatch("encuestas/eliminarPregunta", {id: this.idPreguntaSeleccionada});
      this.loader.hide();
      if (!resp?.success) {
        this.error = true;
        this.mensajeProceso.push(
          resp?.errorMessage + ", código " + resp?.status
        );
        document.getElementById("modalButton").click();
        return;
      }
      this.mensajeProceso.push(resp?.successMessage);
      document.getElementById("modalButton").click();
    }, 
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    encuestaSeleccionada() {
      return this.$store.getters["encuestas/encuestaSeleccionada"]
        ? this.$store.getters["encuestas/encuestaSeleccionada"]
        : null;
    },
    preguntas() {
      return this.$store.getters["encuestas/preguntas"]
        ? this.$store.getters["encuestas/preguntas"]
        : null;
    },
  },
};
</script>
