<template>
  <div
    class="col-md-8 col-sm-12 offset-lg-2 offset-sm-0 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-sample">
              <div class="section-heading">
                <h4 class="card-title">Agregar una exclusión</h4>
              </div>
              <Form
                @submit="submit"
                :validation-schema="formValidation"
                v-slot="{ errors }"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>ECB *</label>
                          <fieldset>
                            <Field
                              as="select"
                              name="ecb"
                              class="form-control"
                              placeholder="ECB"
                              v-model.trim="formData.ecb"
                              @change="consultarClientes"
                            >
                              <option value="" disabled>
                                Seleccione un ECB
                              </option>
                              <option
                                v-for="item in ecbs"
                                :key="item?.descripcion"
                                :value="item?.descripcion"
                              >
                                {{ item?.descripcion }}
                              </option>
                            </Field>
                            <MensajeValidacion :mensaje="errors.ecb" />
                          </fieldset>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="fechaInicio">Fecha inicial *</label>
                          <fieldset>
                            <Field
                              type="date"
                              name="fechaInicio"
                              id="fechaInicio"
                              class="form-control"
                              placeholder="Fecha inicial"
                              v-model.trim="formData.fechaInicio"
                              :min="min"
                              :max="max"
                            />
                            <MensajeValidacion :mensaje="errors.fechaInicio" />
                          </fieldset>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="fechaFin">Fecha final *</label>
                          <fieldset>
                            <Field
                              type="date"
                              name="fechaFin"
                              id="fechaFin"
                              class="form-control"
                              placeholder="Fecha final"
                              v-model.trim="formData.fechaFin"
                              :min="min"
                              :max="max"
                            />
                            <MensajeValidacion :mensaje="errors.fechaFin" />
                          </fieldset>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Pilar *</label>
                          <fieldset>
                            <Field
                              as="select"
                              name="pilar"
                              class="form-control"
                              placeholder="Pilar"
                              v-model.trim="formData.pilar"
                              @change="consultarMotivos"
                            >
                              <option value="" disabled>
                                Seleccione un pilar
                              </option>
                              <option
                                v-for="item in pilares"
                                :key="item"
                                :value="item"
                              >
                                {{ item }}
                              </option>
                            </Field>
                            <MensajeValidacion :mensaje="errors.pilar" />
                          </fieldset>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Motivo *</label>
                          <fieldset>
                            <Field
                              as="select"
                              name="motivo"
                              class="form-control"
                              placeholder="Motivo"
                              v-model.trim="formData.motivo"
                            >
                              <option value="" disabled>
                                Seleccione un motivo
                              </option>
                              <option
                                v-for="item in motivos"
                                :key="item?.id"
                                :value="item?.descripcion"
                              >
                                {{ item?.descripcion }}
                              </option>
                            </Field>
                            <MensajeValidacion :mensaje="errors.motivo" />
                          </fieldset>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Tipo *</label>
                          <fieldset>
                            <Field
                              as="select"
                              name="opcion"
                              class="form-control"
                              placeholder="Clientes"
                              v-model.trim="opcion"
                              @change="mostrarClientes"
                            >
                              <option value="" disabled>
                                Seleccione una opción
                              </option>
                              <option
                                v-for="item in opciones"
                                :key="item"
                                :value="item"
                              >
                                {{ item }}
                              </option>
                            </Field>
                            <MensajeValidacion :mensaje="errors.opcion" />
                          </fieldset>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="habilitarSubirArchivo">
                        <label for="formFileClientes" class="form-label"
                          >Seleccione el archivo de clientes</label
                        >
                        <input
                          class="form-control"
                          type="file"
                          id="formFileClientes"
                          ref="fileClientes"
                          @change="uploadFileCliente"
                        />
                      </div>
                      <div
                        class="col-md-6"
                        v-if="clientesSeleccionados?.length"
                      >
                        <p>Lista de clientes seleccionados</p>
                        <ul>
                          <li v-for="item in clientesSeleccionados" :key="item">
                            <strong>{{ item }}&nbsp;&nbsp;</strong>
                            <a
                              href="javascript:void(0)"
                              class="text-danger"
                              @click="eliminarClienteSeleccionado(item)"
                              ><i class="ti-close"></i
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="input-group col-md-6 mt-2 no-padding"
                        v-if="habilitarClientes"
                      >
                        <input
                          type="text"
                          class="form-control"
                          v-model="valorBusqueda"
                          aria-label="Text input with segmented dropdown button"
                        />
                        <div class="input-group-append">
                          <button
                            type="button"
                            class="btn btn-outline-secondary"
                            @click="filtrarCliente"
                            :disabled="valorBusqueda.length === 0"
                          >
                            Buscar
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3 mb-2">
                      <div class="col-md-6" v-if="clienteFiltrado">
                        <a
                          href="javascript:void(0)"
                          @click="
                            seleccionarCliente(clienteFiltrado?.clienteSap)
                          "
                          >{{ clienteFiltrado?.clienteSap }} -
                          {{ clienteFiltrado?.nombresCliente }}</a
                        >
                      </div>
                    </div>
                    <div class="vertical-scrollable" v-if="habilitarClientes">
                      <div class="row">
                        <a
                          href="javascript:void(0)"
                          class="col-md-6 mt-2 textLink"
                          v-for="item in clientesFiltrados"
                          :key="'item' + item?.id"
                          @click="seleccionarCliente(item?.clienteSap)"
                          >{{ item?.clienteSap }} -
                          {{ item?.nombresCliente }}</a
                        >
                      </div>
                    </div>
                    <!-- <div class="vertical-scrollable" v-if="habilitarClientes">
                      <div class="row pl-4">
                        <div
                          class="form-check pl-4 col-md-6"
                          v-for="item in clientesFiltrados"
                          :key="item?.id"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :value="item?.clienteSap"
                            :id="'flex' + item?.id"
                            @change="seleccionarFila($event)"
                          />
                          <label
                            class="form-check-label"
                            :for="'flex' + item?.id"
                          >
                            {{ item?.clienteSap }} - {{ item?.nombresCliente }}
                          </label>
                        </div>
                      </div>
                    </div> -->
                    <div class="row">
                      <div class="col-md-6">
                        <label for="formFile" class="form-label"
                          >Cargar archivo de respaldo</label
                        >
                        <input
                          class="form-control"
                          type="file"
                          id="formFile"
                          ref="file"
                          @change="uploadFile"
                        />
                      </div>
                      <div class="col-md-6" v-if="archivosArray.length > 0">
                        <label for="formFile" class="form-label"
                          >Lista de archivos de respaldo</label
                        >
                        <ul>
                          <li
                            v-for="(archivo, $index) in archivosArray"
                            :key="$index"
                          >
                            {{ archivo?.name }}
                            <a
                              href="javascript:void(0)"
                              class="text-danger"
                              @click="eliminarArchivo(archivo)"
                              ><i class="ti-close"></i
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage :mensaje="mensaje" v-if="error" />
                </div>

                <div class="row mt-3">
                  <div class="col-lg-12">
                    <button
                      type="submit"
                      id="form-submit"
                      class="btn btn-primary mr-2"
                    >
                      Guardar
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="cerrar"
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <ModalMessages
          :mensaje="mensajeProceso"
          titulo="Exclusiones"
          :method="regresar"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../components/ErrorMessage";
import ModalMessages from "../../../components/ModalMessages.vue";

export default {
  name: "ExclusionForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
    ModalMessages,
  },
  data() {
    const formValidation = {
      ecb: "required",
      fechaInicio: "required",
      fechaFin: "required",
    };
    return {
      formData: {
        ecb: "",
        tipo: "",
        fechaInicio: "",
        fechaFin: "",
        motivo: "",
        pilar: "",
      },
      error: false,
      opcion: "",
      mensaje: "",
      formValidation,
      archivosArray: [],
      pilares: ["TECNICO", "PEOPLE", "RTM"],
      opciones: ["Seleccionar clientes", "Toda la ruta", "Subir archivo"],
      habilitarClientes: false,
      habilitarSubirArchivo: false,
      clientesSeleccionados: [],
      archivoCliente: null,
      mensajeProceso: [],
      ejecutarCerrar: false,
      clientesFiltrados: [],
      valorBusqueda: "",
      clienteFiltrado: null,
    };
  },
  mounted() {
    this.consultarEcbs();
    this.consultarMesActual();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarEcbs() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("catalogos/consultarEcbs");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async consultarMesActual() {
      try {
        await this.$store.dispatch("cortes/consultarMesActual");
      } catch (error) {
        alert("No se pudo realizar la consulta de mes actual");
      }
    },
    async consultarClientes() {
      this.clientesSeleccionados = [];
      this.clientesFiltrados = [];
      try {
        await this.$store.dispatch("catalogos/consultarClientesEcb", {
          ecb: this.formData?.ecb,
        });
        this.clientesFiltrados = this.clientes;
      } catch (error) {
        console.log(error);
      }
    },
    async consultarMotivos() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("catalogos/consultarMotivosXPilar", {
          pilar: this.formData.pilar,
        });
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    mostrarClientes() {
      this.archivoCliente = null;
      this.clientesSeleccionados = [];
      this.clienteFiltrado = null;
      if (this.opcion === "Seleccionar clientes") {
        this.habilitarClientes = true;
        this.habilitarSubirArchivo = false;
        this.formData.tipo = this.opcion;
        return;
      }
      if (this.opcion === "Toda la ruta") {
        this.habilitarClientes = false;
        this.habilitarSubirArchivo = false;
        this.formData.tipo = this.opcion;
        return;
      }
      if (this.opcion === "Subir archivo") {
        this.habilitarClientes = false;
        this.habilitarSubirArchivo = true;
        this.formData.tipo = "Archivo";
        return;
      }
    },
    seleccionarFila(e) {
      let checked = e?.target?.checked;
      let valor = e?.target?.value;
      if (checked) {
        this.clientesSeleccionados.push(parseInt(valor));
      } else {
        const filtrados = this.clientesSeleccionados.filter(
          (item) => item !== parseInt(valor)
        );
        this.clientesSeleccionados = filtrados;
      }
    },
    uploadFile() {
      this.archivosArray.push(this?.$refs?.file?.files[0]);
    },
    uploadFileCliente() {
      this.archivoCliente = this?.$refs?.fileClientes?.files[0];
    },
    eliminarArchivo(archivo) {
      const archivosFiltrados = this.archivosArray?.filter(
        (item) => item !== archivo
      );
      this.archivosArray = archivosFiltrados;
    },
    filtrarCliente() {
      const clienteFiltrado = this.clientes?.find(
        (item) => item?.clienteSap === this.valorBusqueda
      );
      if (clienteFiltrado) {
        this.clienteFiltrado = clienteFiltrado;
      } else {
        this.clienteFiltrado = null;
      }
      /* if (clienteFiltrado) {
        const clientesFiltradosTemp = this.clientes?.filter(
          (item) => item?.clienteSap !== clienteFiltrado?.clienteSap
        );
        this.clientesFiltrados = [clienteFiltrado, ...clientesFiltradosTemp];
      } */
    },
    seleccionarCliente(cliente) {
      if (this.clientesSeleccionados?.includes(cliente)) {
        this.clienteFiltrado = null;
        return;
      }
      this.clientesSeleccionados = [...this.clientesSeleccionados, cliente];
      this.clienteFiltrado = null;
    },
    cargarTodos() {
      this.clientesFiltrados = this.clientes;
    },
    eliminarClienteSeleccionado(cliente) {
      this.clientesSeleccionados = this.clientesSeleccionados?.filter(
        (item) => item !== cliente
      );
    },
    async submit() {
      let fechaInicioDividida = this.formData.fechaInicio.split("-");
      let fechaFinDividida = this.formData.fechaFin.split("-");

      const formData = new FormData();
      this.archivosArray?.map((item) => formData.append("adjuntos", item));
      if (this.archivoCliente) {
        formData.append("clientesFile", this.archivoCliente);
      }
      formData.append("clientes", this.clientesSeleccionados || []);
      formData.append("ecb", this.formData.ecb);
      formData.append("diaInicial", `${fechaInicioDividida[2]}`);
      formData.append("diaFinal", `${fechaFinDividida[2]}`);
      formData.append("motivo", this.formData.motivo);
      formData.append("pilar", this.formData.pilar);
      formData.append("tipo", this.formData.tipo);

      this.loaderSpinner();
      try {
        const resp = await this.$store.dispatch("exclusiones/crear", formData);
        //this.$router.go(-1);
        this.mensajeProceso = [resp[1]];
        document.getElementById("modalButton").click();
        if (resp[0] === "OK") {
          this.ejecutarCerrar = true;
        }
      } catch (error) {
        this.mensajeProceso = [
          "Existió un problema al momento de guardar la esclusión",
        ];
        document.getElementById("modalButton").click();
      } finally {
        this.loader.hide();
      }
    },
    regresar() {
      if (this.ejecutarCerrar) {
        this.$router.go(-1);
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    ecbs() {
      return this.$store.getters["catalogos/ecbs"]
        ? this.$store.getters["catalogos/ecbs"]
        : [];
    },
    clientes() {
      return this.$store.getters["catalogos/clientes"]
        ? this.$store.getters["catalogos/clientes"]
        : [];
    },
    motivos() {
      return this.$store.getters["catalogos/motivos"]
        ? this.$store.getters["catalogos/motivos"]
        : [];
    },
    rango() {
      return this.$store.getters["cortes/rango"]
        ? this.$store.getters["cortes/rango"]
        : {};
    },
    min() {
      const fechaActual = new Date();
      const anioActual = fechaActual.getFullYear();
      const mesActual = this.rango?.mes;
      let mesActualFormateado =
        mesActual > 9 ? mesActual : `0${mesActual}`;
      let diaInicialFormateado =
        this.rango?.diaInicial > 9
          ? this.rango?.diaInicial
          : `0${this.rango?.diaInicial}`;
      const minRango = `${anioActual}-${mesActualFormateado}-${diaInicialFormateado}`;
      return minRango;
    },
    max() {
      const fechaActual = new Date();
      const anioActual = fechaActual.getFullYear();
      const mesActual = this.rango?.mes;
      let mesActualFormateado =
        mesActual > 9 ? mesActual : `0${mesActual}`;
      let diaFinalFormateado =
        this.rango?.diaFinal > 9
          ? this.rango?.diaFinal
          : `0${this.rango?.diaFinal}`;
      const maxRango = `${anioActual}-${mesActualFormateado}-${diaFinalFormateado}`;
      return maxRango;
    },
  },
};
</script>
<style>
.vertical-scrollable {
  /* position: absolute;
  top: 320px;
  bottom: 100px;
  left: 180px;
  width: 90%;*/
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.col-sm-8 {
  color: white;
  font-size: 24px;
  padding-bottom: 20px;
  padding-top: 18px;
}
.col-sm-8:nth-child(2n + 1) {
  background: green;
}
.col-sm-8:nth-child(2n + 2) {
  background: black;
}
.textLink {
  font-size: smaller;
}
</style>
