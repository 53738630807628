<template>
  <div
    class="modal fade"
    id="modalCargarArchivo"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalCargarArchivoLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <form>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalCargarArchivoLabel">
              <strong>{{ titulo }}</strong>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="method"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <p>Seleccione el archivo</p>
              <div class="input-group mb-3">
                <input
                  type="file"
                  @change="uploadFile"
                  ref="file"
                  class="form-control"
                  id="file"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="cargar({ archivo })"
              :disabled="archivo ? false : true"
              data-dismiss="modal"
            >
              Procesar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    titulo: String,
    cargar: { type: Function },
    fecha: Date,
  },
  data() {
    return {
      archivo: null,
    };
  },
  mounted() {},
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
    },
  },
  computed: {},
  watch: {
    fecha(oldFecha, newFecha) {
      if (oldFecha !== newFecha) {
        this.archivo = null;
        let inputImage = document.getElementById("file");
        inputImage.value = "";
      }
    },
  },
};
</script>
