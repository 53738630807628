import axios from "axios";

const state = {
  pilares: null,
  pilarSeleccionado: null,
  pilarUsuario: null,
  mensaje: "",
};

const getters = {
  pilares: (state) => state.pilares,
  pilarSeleccionado: (state) => state.pilarSeleccionado,
  pilarUsuario: (state) => state.pilarUsuario,
};

const mutations = {
  setPilares: (state, pilares) => {
    state.pilares = pilares;
  },
  setPilarSeleccionado: (state, pilarSeleccionado) => {
    state.pilarSeleccionado = pilarSeleccionado;
  },
  setPilarUsuario: (state, pilarUsuario) => {
    state.pilarUsuario = pilarUsuario;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
  inicializar: (state) => {
    state.pilares = null;
    state.pilarSeleccionado = null;
    state.pilarUsuario = null;
    state.mensaje = "";
  },
};

const actions = {
  async consultarAll({ commit }) {
    try {
      let res = await axios.get(`/v1/pilar-usuario`);
      commit("setPilares", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async crear({ commit }, data) {
    try {
      await axios.post(`/v1/pilar-usuario`, data);
      commit("setMensaje", "Se creó exitosamente el pilar-usuario");
    } catch (error) {
      return error;
    }
  },
  async editar({ commit }, data) {
    try {
      await axios.put(`/v1/pilar-usuario/${data.idPilarUsuario}`, data?.data);
      commit("setMensaje", "Se actualizó exitosamente el pilar-usuario");
    } catch (error) {
      return error;
    }
  },
  async consultarByUsuario({ commit }) {
    try {
      let res = await axios.get(`/v1/pilar-usuario/usuario`);
      commit("setPilarUsuario", res?.data?.result?.pilar);
    } catch (error) {
      return error;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
