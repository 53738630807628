<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-3">
            <h3 class="font-weight-bold">Gestión de Encuestas</h3>
          </div>
          <div class="col-md-9">
            <button @click="agregar" class="btn btn-primary float-right">
              <span class="icon-plus"></span> Agregar encuesta
            </button>
          </div>
        </div>
        <br />
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Asignacion</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in registros" :key="item.id">
                <td width="100">
                  <a
                    style="margin-right: 10px"
                    href="javascript:void(0)"
                    @click="agregarPreguntas(item)"
                    ><i class="ti-pencil"></i
                  ></a>
                  <a
                    style="margin-right: 10px"
                    href="javascript:void(0)"
                    @click="asignar(item.id)"
                    ><i class="ti-user"></i
                  ></a>
                  <a href="javascript:void(0)" @click="eliminar(item.id)"
                    ><i class="ti-trash"></i
                  ></a>
                </td>
                <td>
                  {{ item.nombre }}
                </td>
                <td>
                  {{ item.descripcion }}
                </td>
                <td>
                  <span
                    class="badge badge-info"
                    v-for="(rol, index) in item.rolAignados"
                    :key="index"
                  >
                    {{ rol }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Modal :mensaje="mensaje" :method="confirmarEliminar" />
        <ModalMessages :mensaje="mensajeProceso" titulo="Eliminar encuesta" />
        <ModalAsignacion
          :method="procesarAsignacion"
          :close="updateComponentHide"
          :componentHide="componentHide"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ModalMessages from "../../../components/ModalMessages.vue";
import Modal from "../../../components/Modal.vue";
import ModalAsignacion from "./ModalAsignacion.vue";
export default {
  name: "GestionEncuestas",
  components: {
    ModalMessages,
    Modal,
    ModalAsignacion,
  },
  data() {
    return {
      preguntasRegistradas: [],
      idEncuestaSeleccionada: 0,
      mensajeProceso: [],
      mensaje: "",
      componentHide: false,
      encuesta: 0,
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("encuestas/consultar");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      this.$router.push({ name: "EncuestaForm" });
    },
    async agregarPreguntas(encuesta) {
      await this.$store.dispatch("encuestas/consultarPreguntasByEncuesta", {
        idEncuesta: encuesta?.id,
      });
      this.$router.push({ name: "DetalleEncuesta" });
    },
    eliminar(id) {
      this.idEncuestaSeleccionada = id;
      this.mensaje = "¿Desea eliminar la información?";
      document.getElementById("modalButtonConfirmacion").click();
    },
    async confirmarEliminar() {
      this.mensajeProceso = [];
      this.mensaje = "";
      this.loaderSpinner();
      const resp = await this.$store.dispatch("encuestas/eliminarEncuesta", {
        id: this.idEncuestaSeleccionada,
      });
      this.loader.hide();
      if (!resp?.success) {
        this.mensajeProceso.push(
          resp?.errorMessage + ", código " + resp?.status
        );
        document.getElementById("modalButton").click();
        return;
      }
      this.mensajeProceso.push(resp?.successMessage);
      document.getElementById("modalButton").click();
    },
    asignar(id) {
      this.componentHide = true;
      this.encuesta = id;
    },
    updateComponentHide() {
      this.componentHide = false;
    },
    async procesarAsignacion(role) {
      this.componentHide = false;
      this.mensajeProceso = [];
      this.mensaje = "";
      this.loaderSpinner();
      const data = {
        encuesta: this.encuesta,
        rol: role,
      };
      const resp = await this.$store.dispatch("encuestas/asignar", data);
      this.loader.hide();
      if (!resp?.success) {
        this.error = true;
        this.mensajeProceso.push(
          resp?.errorMessage + ", código " + resp?.status
        );
        document.getElementById("modalButton").click();
        return;
      }
      
      this.mensajeProceso.push(resp?.successMessage);
      document.getElementById("modalButton").click();
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },

    registros() {
      return this.$store.getters["encuestas/registros"]
        ? this.$store.getters["encuestas/registros"]
        : false;
    },
    preguntas() {
      return this.$store.getters["encuestas/preguntas"]
        ? this.$store.getters["encuestas/preguntas"]
        : null;
    },
  },
};
</script>
