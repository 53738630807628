<template>
    <div class="row">
        <div class="col-md-2 header-column-color">
            <div class="header-title">
                <strong>Tipo de consulta</strong>
            </div>
        </div>
        <div class="col-md-2 header-column-color">
            <div class="header-title">
                <strong>Acceso a</strong>
            </div>
        </div>
        <div class="col-md-8 header-hours-padding">
            <div class="header-hours-wrap">
                <div v-for="(item, index)  in horas" :key="index" class="header-hours">
                    <strong>{{ index }}</strong>
                </div>
                <div class="header-total-hours">
                    <strong>Total general</strong>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            horas: Array.from({ length: 24 }, (value, index) => index)
        }
    }
}
</script>
<style>
  .header-column-color {
    background-color: #F0F0F0; 
    text-align: center;
  }
  .header-title {
    padding: 10px; 
    font-size: 12px;
  }
  .header-hours-padding {
    padding-left: 0px;
  }
  .header-hours-wrap {
    display: flex; 
    flex-wrap: wrap; 
    width: 130%
  }
  .header-hours {
    background-color: #F0F0F0; 
    text-align: center; 
    width: 80px; 
    padding: 12px; 
    font-size: 12px;
  }
  .header-total-hours {
    background-color: #F0F0F0; 
    text-align: center; 
    width: 80px; 
    padding: 5px; 
    font-size: 12px;
  }
</style>
