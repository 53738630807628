import axios from "axios";

const state = {
    registros: null
};

const getters = {
    registros: (state) => state.registros,
};

const mutations = {
    setRegistros: (state, registros) => {
        state.registros = registros;
    }
};

const actions = {
    async consultar({commit}) {
        try {
            let res = await axios.get(
                `/v1/logistica`
            );
            commit("setRegistros", res?.data?.result);
        } catch (error) {
            return error;
        }
    },

    async cargar(_, formCompuesto) {
        try {
            let res = await axios.post(
                '/v1/logistica/cargar/'+formCompuesto.sobreecribir, formCompuesto.form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            return {success: true, data: res.data.result};
        } catch (error) {
            return {success: false, error: error.response.data, status: error.response.status};
        }
    }


};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
