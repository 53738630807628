<template>
  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <ul class="nav">

      <li class="nav-item" v-if="rol === 'ROLE_USER' || rol === 'ROLE_KKAA_HE' || rol === 'ROLE_GERENTE_SERINSE'">
        <router-link class="nav-link" href="#" :to="{ name: 'ExclusionForm' }">
          <i class="ti-plus menu-icon"></i>
          <span class="menu-title">Nueva Exclusión</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="rol === 'ROLE_USER' || rol === 'ROLE_KKAA_HE' || rol === 'ROLE_GERENTE_SERINSE'">
        <router-link class="nav-link" href="#" :to="{ name: 'Exclusiones' }">
          <i class="ti-files menu-icon"></i>
          <span class="menu-title">Exclusiones Pendientes</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="rol === 'ROLE_USER' || rol === 'ROLE_KKAA_HE' || rol === 'ROLE_GERENTE_SERINSE'">
        <router-link class="nav-link" href="#" :to="{ name: 'ExclusionesAprobadasRechazadas' }">
          <i class="ti-check-box menu-icon"></i>
          <span class="menu-title">Aprobadas/rechazadas</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="rol === 'ROLE_USER' || rol === 'ROLE_KKAA_HE' || rol === 'ROLE_GERENTE_SERINSE'">
        <router-link class="nav-link" href="#" :to="{ name: 'ReporteAcumuladoUsuario' }">
          <i class="ti-files menu-icon"></i>
          <span class="menu-title">Reporte</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="rol === 'ROLE_ADMIN_EXCLUSION'">
        <router-link class="nav-link" href="#" :to="{ name: 'Configuracion' }">
          <i class="ti-settings menu-icon"></i>
          <span class="menu-title">Configuración</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="rol === 'ROLE_AUDITOR'">
        <router-link class="nav-link" href="#" :to="{ name: 'ExclusionesAdmin' }">
          <i class="ti-files menu-icon"></i>
          <span class="menu-title">Procesar Exclusiones</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="rol === 'ROLE_AUDITOR'">
        <router-link class="nav-link" href="#" :to="{ name: 'ReporteAcumuladoAuditor' }">
          <i class="ti-files menu-icon"></i>
          <span class="menu-title">Reporte</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="rol === 'ROLE_ADMIN_EXCLUSION'">
        <router-link class="nav-link" href="#" :to="{ name: 'Usuarios' }">
          <i class="ti-files menu-icon"></i>
          <span class="menu-title">Gerencia Usuario</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="rol === 'ROLE_ADMIN_EXCLUSION'">
        <router-link class="nav-link" href="#" :to="{ name: 'Pilares' }">
          <i class="ti-files menu-icon"></i>
          <span class="menu-title">Pilar Usuario</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="rol === 'ROLE_ADMIN_EXCLUSION'">
        <router-link class="nav-link" href="#" :to="{ name: 'ReporteAcumulado' }">
          <i class="ti-files menu-icon"></i>
          <span class="menu-title">Reporte</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="rol === 'ROLE_ADMIN_PEDIDOS'">
        <router-link class="nav-link" href="#" :to="{ name: 'Pedidos' }">
          <i class="ti-shopping-cart menu-icon"></i>
          <span class="menu-title">Pedidos</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="rol === 'ROLE_ADMIN_LOGISTICA'">
        <router-link class="nav-link" href="#" :to="{ name: 'Logistica' }">
          <i class="ti-bag menu-icon"></i>
          <span class="menu-title">Logistica</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="rol === 'ROLE_ADMIN_LOGISTICA'">
        <router-link class="nav-link" href="#" :to="{ name: 'Encuestas' }">
          <i class="ti-pencil-alt menu-icon"></i>
          <span class="menu-title">Encuestas</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="rol === 'ROLE_REPORTS'">
        <router-link class="nav-link" href="#" :to="{ name: 'Reportes' }">
          <i class="ti-receipt menu-icon"></i>
          <span class="menu-title">Reportes</span>
        </router-link>
      </li>

    </ul>
  </nav>
</template>
<script>
export default {
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
    },
  },
  computed: {
    rol() {
      if (!this?.$store?.state?.auth?.rol) {
        this.logout();
        return;
      }
      return this.$store.state.auth.rol;
    },
  },
};
</script>
