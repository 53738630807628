import axios from "axios";

const state = {
  registros: null,
  encuestaSeleccionada: null,
  preguntas: null
};

const getters = {
  registros: (state) => state.registros,
  encuestaSeleccionada: (state) => state.encuestaSeleccionada,
  preguntas: (state) => state.preguntas,
};

const mutations = {
  setRegistros: (state, registros) => {
    state.registros = registros;
  },
  setEncuestaSeleccionada: (state, encuestaSeleccionada) => {
    state.encuestaSeleccionada = encuestaSeleccionada;
  },
  setPreguntas: (state, preguntas) => {
    state.preguntas = preguntas;
  },
};

const actions = {
  async consultar({ commit }) {
    try {
      let res = await axios.get(`/v1/encuestas`);
      commit("setRegistros", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async crear(_, data) {
    try {
      const resp = await axios.post(`/v1/encuestas`, data);
      return {
        success: true,
        data: resp?.data?.result,
        successMessage: "Se ha registrado la información con éxito",
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: error?.response?.data?.errors
          ? error?.response?.data?.errors[0]
          : "No se pudo registrar la información",
          status: error?.response?.status
      };
    }
  },
  async consultarPreguntasByEncuesta({ commit }, data) {
    try {
      let res = await axios.get(`/v1/encuestas/${data?.idEncuesta}`);
      commit("setPreguntas", res?.data?.result?.preguntas);
      commit("setEncuestaSeleccionada", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async crearPreguntas(_, data) {
    try {
      const resp = await axios.post(`/v1/encuestas/pregunta`, data);
      return {
        success: true,
        data: resp?.data?.result,
        successMessage: "Se ha registrado la información con éxito",
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: error?.response?.data?.errors
          ? error?.response?.data?.errors[0]
          : "No se pudo registrar la información",
          status: error?.response?.status
      };
    }
  },
  async eliminarEncuesta({state, commit}, data) {
    try {
      const resp = await axios.delete(`/v1/encuestas/${data.id}`);
      const dataFiltrada = state.registros?.filter(item => item.id !== data.id);
      commit("setRegistros", dataFiltrada ?? []);
      return {
        success: true,
        data: resp?.data?.result,
        successMessage: "Se ha eliminado la información con éxito",
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: error?.response?.data?.errors
          ? error?.response?.data?.errors[0]
          : "No se pudo eliminar la información",
          status: error?.response?.status
      };
    }
  },
  async eliminarPregunta({state, commit}, data) {
    try {
      const resp = await axios.delete(`/v1/encuestas/pregunta/${data.id}`);
      const dataFiltrada = state.preguntas?.filter(item => item.id !== data.id);
      commit("setPreguntas", dataFiltrada ?? []);
      return {
        success: true,
        data: resp?.data?.result,
        successMessage: "Se ha eliminado la información con éxito",
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: error?.response?.data?.errors
          ? error?.response?.data?.errors[0]
          : "No se pudo eliminar la información",
          status: error?.response?.status
      };
    }
  },
  async asignar(_, data) {
    try {
      const resp = await axios.post(`/v1/encuestas/asignacion`, data);
      return {
        success: true,
        data: resp?.data?.result,
        successMessage: "Se ha registrado la información con éxito",
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: error?.response?.data?.errors
          ? error?.response?.data?.errors[0]
          : "No se pudo registrar la información",
          status: error?.response?.status
      };
    }
  }
};
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
